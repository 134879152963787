import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import styles from './index.less'
import Dialog from './Dialog';


let debounceTimeout;
const Center = React.forwardRef((props, ref) => {
  const {
    list,
    isMobile = false,
    info = {},
    triggerSend = () => {
    },
    triggerGameSend = () => {
    }
  } = props
  let [centerEl, setCenterEl] = useState('');
  const canvasRef = useRef();

  useEffect(() => {
    console.log(info.uInfo);
    if (canvasRef && canvasRef.current && info.uInfo && info.uInfo.account && info.uInfo.name) {
      reDraw();
      // ctx.fillRect(0, 0, 100, 100);
      // fillRotateText(ctx, '闫肃', 100, 80, -30);
      // fillRotateText(ctx, '闫肃', 340, 80, -30);
      // fillRotateText(ctx, 'yansu1', 100, 268, -30);
      // fillRotateText(ctx, 'yansu1', 340, 268, -30);

    }
  }, [info]);

  const reDraw = () => {
    let clientWidth = canvasRef.current.clientWidth;
    let clientHeight = canvasRef.current.clientHeight;
    let ctx = canvasRef.current.getContext('2d');
    canvasRef.current.width = clientWidth;
    canvasRef.current.height = clientHeight;
    ctx.width = clientWidth;
    ctx.height = clientHeight;
    ctx.font = "14px arial";
    ctx.fillStyle = "rgba(206,206,206,.3)";
    ctx.textBaseline = 'top';
    let startAt = {x: 100, y: 80};
    let offsetX = 240;
    let offsetY = 188;

    for (let x = startAt.x; x < clientWidth; x = x + offsetX) {
      for (let y = startAt.y; y < clientHeight; y = y + offsetY * 2) {
        fillRotateText(ctx, info.uInfo.name, x, y, -30);
        fillRotateText(ctx, info.uInfo.account, x, y + offsetY, -30);
      }
    }
  }

  window.addEventListener('resize', reDraw);


  const fillRotateText = (ctx, str, x, y, r) => {
    //位移
    ctx.translate(x, y);
    //旋转
    ctx.rotate((Math.PI / 180) * r);
    ctx.fillText(str, 0, 0);
    //回旋
    ctx.rotate((Math.PI / 180) * -r);
    //复位
    ctx.translate(-x, -y);
  }


  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      scrollToBottom(300);
    },
  }));

  function scrollToBottom(time) {
    clearTimeout(debounceTimeout);
    if (!centerEl) {
      return
    }
    if (time === 0) {
      const Height = centerEl.scrollHeight // 真实高度
      const ShowHeight = centerEl.clientHeight // 可见高度
      const MaxScrollTop = Height - ShowHeight
      $(centerEl).animate({
        scrollTop: MaxScrollTop > 0 ? MaxScrollTop : 0
      }, 0)
      return;
    }
    debounceTimeout = setTimeout(function () {
      const Height = centerEl.scrollHeight // 真实高度
      const ShowHeight = centerEl.clientHeight // 可见高度
      const MaxScrollTop = Height - ShowHeight
      $(centerEl).animate({
        scrollTop: MaxScrollTop > 0 ? MaxScrollTop : 0
      }, time != undefined ? time : (isMobile ? 250 : 300))
    }, 200);
    // centerEl.scrollTop = MaxScrollTop > 0 ? MaxScrollTop : 0
  }

  useEffect(() => {
    if (centerEl) {
      setTimeout(() => {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      });
    }

  }, [list, centerEl]);

  return (
    <div className={styles['content-wrap']}>
      <div className={styles['canvas-container']}>
        <canvas ref={canvasRef}/>
      </div>
      <div className={styles.kf_content} ref={el => setCenterEl(el)}>

        {list.length !== 0 && list.map((dialog, index) => {
          return <Dialog scrollToBottom={scrollToBottom} {...dialog} key={index} info={info} isMobile={isMobile} triggerSend={triggerSend}
                         triggerGameSend={triggerGameSend}/>
        })}
      </div>
    </div>

  )
})

export default (Center);