import React, {useState, useEffect, useRef} from 'react';
import styles from './index.less'
import Slider from "react-slick";
import {generateWid} from "../../static/util/utils";
import {getWXOpenID, hasCollectedClues} from '../../static/util/store';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {isWeixin, toast} from "../../static/util/utils";

import {useTranslation} from "react-i18next";
import xssOptions from '../../static/xssFiltersOptions';
import 'emoji-mart/css/emoji-mart.css';
import {Picker, Emoji} from 'emoji-mart';

const MoreFun = (props) => {


  const {
    title = "",
    icon = "",
    jumpUrl = "",
    callback,
    isFile = false,
  } = props;


  const inputRef = useRef();
  const inputOnChange = (e) => {
    const files = e.target && e.target.files;
    callback && callback(files);
    e.target.value = '';
  }

  const clickHandler = () => {
    if (callback) {
      if (isFile) {
        inputRef.current && inputRef.current.click();
      } else {
        return callback();
      }

    }
    jumpUrl && (window.location = jumpUrl);
  }

  return (
    <div style={{display: 'inline-block'}}>
      <input
        onChange={inputOnChange}
        ref={inputRef} type="file" accept={props.accept || 'image/*'} style={{display: 'none'}}/>
      <div className={styles['fun_container']} onClick={() => {
        clickHandler();
      }}>

        <svg className={`icon ${styles.fun_icon}`} aria-hidden="true">
          <use xlinkHref={`#icon-zhike-${icon}`}/>
        </svg>
        <p className={styles['fun_desc']}>{title}</p>
      </div>
    </div>

  )
}

const MoreFunPC = (props) => {
  const {
    icon = "",
    jumpUrl = "",
    callback,
    isFile = false,
  } = props;


  const inputRef = useRef();
  const inputOnChange = (e) => {
    const files = e.target && e.target.files;
    callback && callback(files);
    e.target.value = '';
  }

  const clickHandler = () => {
    if (callback) {
      if (isFile) {
        inputRef.current && inputRef.current.click();
      } else {
        return callback();
      }

    }
    jumpUrl && (window.location = jumpUrl);
  }

  return (
    <div style={{display: 'inline-block'}}>
      <input
        onChange={inputOnChange}
        ref={inputRef} type="file" accept={props.accept || 'image/*'} style={{display: 'none'}}/>


      <svg className={`icon ${styles.icon}`}
           onClick={() => {
             clickHandler();
           }}
           aria-hidden="true"
      >
        <use xlinkHref={`#icon-zhike-${icon}`}/>
      </svg>

    </div>

  )

}

const tostTip = toast();

import emojiMap from '../../static/emojiMap';
import {querySuggest, uploadImg} from "../../services";
import * as xssFilters from "xss";
import radarLog from "../../static/util/radar";


//特殊id放开线索收集
const queryString = require('query-string');
const rid = queryString.parse(location.search).rid;


/**
 * @param {function} props.tigger 发送按钮的回调函数
 * @param {boolean} props.isMobile
 */
export default function Footer(props) {
  const {
    info,
    triggerSend,
    triggerChange = () => {
    },
    setShowMoreFun = () => {

    },
    isMobile = false,
    isBan = false,
    isHide = false,
    showNewInformationTips = false,
    newInformationNumber,
    setShowNewInformationTips = () => {
    },
    setNewInformationNumber = () => {
    },
    showChange = false,
    showMoreFun = false,
    isIMConnect = false,
    isInputing = false,
    moreFunList = [],
    connectIM = () => {

    },
    fileUpload = () => {

    },
    uploadImg = () => {

    },
    imWaiting,
    closeImWaiting = () => {

    },
    collectClues = () => {

    },
    querySuggest,
    submitInput,
    canCollect = () => {
    },
    closeHandle = () => {

    },
    isSDK,
    waitQueueInfo,
    imWaitingNetWorkError = false,
    stopQuestion = () => {

    },
    eventSourceState,
    entranceFormInfo = null,
    showEntranceForm = false,
    openForm = ()=>{
    },
  } = props;

  const {t} = useTranslation();
  const inputRef = useRef();
  const emojiRef = useRef();
  const [showEmoji, setShowEmoji] = useState(false);
  const [suggestData, setSuggestData] = useState([]);
  const [suggestTimeout, setSuggestTimeout] = useState(null);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [inputStatus, setInputStatus] = useState(false);

  useEffect(() => {
    console.log('entranceFormInfo', entranceFormInfo);
  }, [entranceFormInfo]);

  const wid = generateWid();

  const getInputText = () => {
    let text;
    try {
      if (inputRef?.current) {
        let nodeName = inputRef?.current?.nodeName;
        if (nodeName === 'TEXTAREA' || nodeName === 'INPUT') {
          text = inputRef?.current?.value;
        } else {
          text = inputRef?.current?.innerHTML;
        }
      }
    } catch (e) {
    } finally {
      return text;
    }
  }

  const setInputText = (value) => {
    try {
      if (inputRef?.current) {
        let nodeName = inputRef?.current?.nodeName;
        if (nodeName === 'TEXTAREA' || nodeName === 'INPUT') {
          inputRef.current.value = value;
        } else {
          inputRef.current.innerHTML = value;
        }
      }
    } catch (e) {

    }
  }

  const fetchSuggest = (query, callback) => {
    if (suggestTimeout) {
      clearTimeout(suggestTimeout);
      setSuggestTimeout(null);
    }

    let timeout = setTimeout(async () => {
      if (querySuggest) {
        try {
          let res = await querySuggest(query);
          if (res && res.data && res.data.res) {
            callback(res.data.res);
          }
        } catch (e) {
        }
      }

    }, 300);

    setSuggestTimeout(timeout);

  }

  const uploadInput = (query) => {
    if (inputTimeout) {
      clearTimeout(inputTimeout);
      setInputTimeout(null);
    }

    let timeout = setTimeout(async () => {
      if (submitInput) {
        submitInput({content: query});
      }
    }, 100);
    setInputTimeout(timeout);
  }

  const cleanSuggest = () => {
    setSuggestData([]);
    clearTimeout(suggestTimeout);
    setSuggestTimeout(null);
  }

  // useEffect(() => {
  //   if (text && inputStatus) {
  //     try {
  //       let srcObj = inputRef.current;
  //       let selection = window.getSelection();
  //       let range = document.createRange();
  //       range.selectNodeContents(srcObj);
  //       selection.removeAllRanges();
  //       selection.addRange(range);
  //       range.setStart(srcObj, range.endOffset);
  //       range.setEnd(srcObj, range.endOffset);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // }, [text]);

  useEffect(() => {
    $(window).bind('touchmove', handleScroll);
    document.addEventListener('mousedown', onClickAll, false);
    return () => {
      $(window).unbind('touchmove', handleScroll);
      document.removeEventListener('mousedown', onClickAll, false);
    }
  }, []);

  const scrollToBottom = () => {
    setNewInformationNumber(0);
    setShowNewInformationTips(false);
  };


  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true
  };

  const emojiI18n = {
    categories: {
      search: 'Search Results',
      recent: '常用',
      smileys: '表情',
      people: '表情符号与人物',
      nature: '动物与自然',
      foods: '食物与饮料',
      activity: '活动',
      places: '旅游与地点',
      objects: '物体',
      symbols: '符号',
      flags: '旗帜',
      custom: '自定义',
    },
  };
  useEffect(() => {
    // setText('');
    if (isIMConnect) {
      setInputText('');
      // inputRef.current.innerHTML = '';
    }
    document
      .getElementById('contentArea')?.addEventListener('paste', pasteImg, false);
    return () => {
      document
        .getElementById('contentArea')?.removeEventListener('paste', pasteImg, false);
    }
  }, [isIMConnect])

  //emoji非自身区域点击
  const onClickAll = e => {

    if (emojiRef && emojiRef.current) {
      try {
        if (emojiRef.current.contains(e.target)) {
          return;
        } else {
          setShowEmoji(false);
        }
      } catch (e) {

      }
    }
  };

  //粘贴发送图片、文件
  const pasteImg = async e => {
    if (!(e.clipboardData && e.clipboardData.items)) {
      return;
    }
    if (!isIMConnect) {
      return;
    }
    e.preventDefault();
    if (e.clipboardData.files && e.clipboardData.files.length > 0) {
      for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
        const item = e.clipboardData.items[i];
        if (item.kind === 'file') {
          inputRef.current &&
          inputRef.current.blur &&
          inputRef.current.blur();
          const pic = item.getAsFile();
          const file = [pic];
          if (/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(file[0].name) && false) {
            // let url =  uploadImg(file);
            let res = await uploadImg(file[0]);
            if (res?.data?.url || res?.data?.url_https) {
              let url = res?.data?.url || res?.data?.url_https;
              document.execCommand('insertHTML', false, `<img src="${url}"/>`);
              // setText(pre => {
              //   let result = pre;
              //   result = pre + `<img src="${res.data.url}" />`;
              //   inputRef.current.innerHTML = result;
              //   return result;
              // });
            }
          } else {
            fileUpload(file);
          }
        }
      }
    } else {
      let str = (e.originalEvent || e).clipboardData.getData('text/plain');
      ;
      // if (e.clipboardData?.types?.findIndex(i => i === 'text/html') >= 0) {
      //   str = e.clipboardData.getData('text/html');
      // } else {
      //   str = e.clipboardData.getData('text/plain');
      // }
      str = xssFilters(str, {
        whiteList: {},
        stripIgnoreTag: true, // 过滤所有非白名单标签的HTML
        css: false,
      });
      document.execCommand('insertHTML', false, str);
      // inputRef.current && (inputRef.current.innerHtml += str);
      // setText(pre => {
      //   let result = pre + str;
      //   // inputRef.current.innerHTML = result;
      //   console.log('paste str ',result,inputRef.current);
      //   return result;
      // });
      // console.log(xssFilters(str,xssOptions));
    }

    // let srcObj = inputRef.current;
    // let selection = window.getSelection();
    // let range = document.createRange();
    // range.selectNodeContents(srcObj);
    // selection.removeAllRanges();
    // selection.addRange(range);
    // range.setStart(srcObj, range.endOffset);
    // range.setEnd(srcObj, range.endOffset);
  };

  // 发送数据 + 移动端回车提交
  function handleClick(e) {
    if (e) {
      try {
        e.preventDefault && e.preventDefault();
        inputRef.current && inputRef.current.focus && inputRef.current.focus();
      } catch (e) {

      }

    }
    let text = getInputText();
    radarLog({c: 'rob_send'});
    if (text && text.trim().length >= 1000) {
      tostTip('消息内容过长');
      return;
    } else if (text.replace(/&nbsp;/ig, "").trim().length < 1) {
      tostTip('消息不能为空');
      return;
    }


    if (triggerSend) {
      triggerSend(text)
    }
    // setText('');
    setInputText('');
    cleanSuggest();
    uploadInput('');
  }

  function pcHandleChange(e) {
    if (isIMConnect) {
      fetchSuggest(e.target.innerHTML, (res) => {
        setSuggestData(res);
      });
      uploadInput(e.target.innerHTML);
      // setText(e.target.innerHTML);
      setInputStatus(false);
    } else {
      fetchSuggest(e.target.value, (res) => {
        setSuggestData(res);
      });
      uploadInput(e.target.value);
      // setText(e.target.value);
      setInputStatus(false);
    }
  }

  function handleChange(e) {
    fetchSuggest(e.target.value, (res) => {
      setSuggestData(res);
    });
    uploadInput(e.target.value);
    // setText(e.target.value)
  }

  const sendSuggest = (text, id) => {
    if (triggerSend) {
      triggerSend(text, id)
    }
    setInputText('');
    // setText('');
    setSuggestData([]);
  };

  function handleScroll(e) {

    if (!isMobile) {
      return;
    }

    if (inputRef && inputRef.current) { //&& !inputRef.current.contains(e.target)
      inputRef.current.blur();
    }

  }

  // pc回车提交
  function handleEnter(e) {
    if (e.charCode === 13) {

      handleClick(e)
    }
  }

  function changeRobot(id) {
    // console.log(id,window.location);
    let params = queryString.parse(location.search);
    params.rid = id;
    delete params.launch_id;
    let search = queryString.stringify(params);
    window.location.href = `/?${search}`;
  }

  const toIM = async () => {
    // setWXOpenID('okqWWt1qKtv4OMRYoYXwJTYawHsg');
    radarLog({c: 'zd_transfer'});

    connectIM(false, null, null, 1);
    // const openId = getWXOpenID();
    // let res = await getImChatConnect({partnerid: openId});
    // // clearWXOpenID();
    // tostTip(JSON.stringify(res));
  };

  const renderMobile = () => {

    const renderBtn = () => {
      let text = getInputText();
      if (isIMConnect && moreFunList && moreFunList.length && text === '') {
        return (
          <svg onClick={() => {
            setShowMoreFun(!showMoreFun)
          }} className={`icon ${styles.more_fun_plus}`} aria-hidden="true">
            <use xlinkHref={`#icon-zhike-more-fun-plus`}/>
          </svg>
        )
      } else {
        return (<span className={`${text != '' ? styles['-active'] : ''}`} onClick={handleClick}>{t('send')}</span>)
      }
    }

    const judgeShowEnter = () => {

      if (isIMConnect || imWaiting || isBan || isHide) {
        return null;
      }

      if (info.robot_im_sobot && info.robot_im_sobot.user_identity != '0') {
        if (info.robot_im_sobot.hide_transfer != "1") {
          return <img
            onClick={toIM}
            src={t('im-icon')} className={styles['to-im-icon']}/>
        } else {
          return null;
        }

      }

      if (info.customer_mode && info.customer_mode === 3) {
        return <img
          onClick={toIM}
          src={t('im-icon')} className={styles['to-im-icon']}/>
      }

      return null;

      // {((info.robot_im_sobot && info.robot_im_sobot.user_identity != '0') || info.customer_mode && info.customer_mode === 3) && !isIMConnect && !imWaiting && !isBan ?
      //   (info.robot_im_sobot && info.robot_im_sobot.hide_transfer != "1" ? <img
      //       onClick={toIM}
      //       src={t('im-icon')} className={styles['to-im-icon']}/> : null
      //   ) : null}


    }

    return (
      <form action="" className={styles.mkf_footer} onSubmit={handleClick}>
        {eventSourceState && eventSourceState === 1 ? (
          <div style={{textAlign: 'center'}} className={styles['stop-question-btn']} onClick={stopQuestion}>
            <a>停止问答</a>
          </div>) : null}
        {
          info.relateRobots ? <>
            <div className={styles['relative-robot-container']}>
              {info.relateRobots?.map((value, index) => {
                return (
                  <span onClick={() => {
                    changeRobot(value.robot_id)
                  }} key={index} className={styles['item']}>
                    {index < 2 ? <svg className={`icon`} aria-hidden="true">
                      <use xlinkHref={`#icon-zhike-remen`}/>
                    </svg> : null}
                    <span>
                      {value?.nickname}
                    </span>
                  </span>
                )
              })}
            </div>
          </> : null
        }

        {showChange && (
          <div className={styles.mkf_change} onClick={triggerChange}>
            咨询其他游戏
          </div>
        )}
        {canCollect() ?
          <div className={styles['mkf_change']} onClick={collectClues}>
            快速申请
          </div> : null}

        {
          showEntranceForm ?
            <div className={styles['mkf_change']}  onClick={()=>{openForm(entranceFormInfo)}}>
              {entranceFormInfo?.entrance?.name}
            </div> : null
        }

        <div style={{position: 'relative'}}>
          <div className={styles['suggest-container']}>
            {
              getInputText() && suggestData.map((item, index) => {
                return <div key={index} className={styles['item']} onClick={() => {
                  sendSuggest(item.question, item.record_id)
                }}>
                  <span dangerouslySetInnerHTML={{__html: item.question_format}}></span>
                </div>
              })
            }
          </div>
          <div className={styles['input_container']}>

            {info.customer_mode === 2 && !isIMConnect ? <div className={`${styles['footer-mask-container']}`}>

              <div className={styles['connect-btn']} onClick={toIM}>重新连接</div>
            </div> : null}

            {showNewInformationTips && newInformationNumber > 0 ?
              <div className={styles['new-mobile-information']} style={{color: info.color}} onClick={scrollToBottom}>
                <svg className={styles['new-mobile-information-icon']} aria-hidden="true" style={{fill: info.color}}>
                  <use xlinkHref={`#icon-zhike-double-arrow-down`}/>
                </svg>
                {newInformationNumber}条新消息
              </div> : null}


            {
              judgeShowEnter()
            }
            {
              (imWaiting && imWaiting > 0) ? (imWaitingNetWorkError ? <div className={styles['waiting-im']}>
                <span>网络中断导致排队异常，请点击【</span>
                <a onClick={toIM}>继续排队</a>
                <span>】确保您当前排位</span>
              </div> : <div className={styles['waiting-im']}>
                {waitQueueInfo ? `转人工排队中，当前您排第${waitQueueInfo}位` : "正在为您转接人工客服，请稍后..."}
                <svg onClick={() => {
                  closeImWaiting()
                }} className={`icon ${styles['close-btn']}`} aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-feedback-close`}/>
                </svg>

              </div>) : null
            }

            {/*{((info.robot_im_sobot && info.robot_im_sobot.user_identity != '0') || info.customer_mode && info.customer_mode === 3) && !isIMConnect && !imWaiting && !isBan ?*/}
            {/*  (info.robot_im_sobot && info.robot_im_sobot.hide_transfer != "1" ? <img*/}
            {/*      onClick={toIM}*/}
            {/*      src={t('im-icon')} className={styles['to-im-icon']}/> : null*/}
            {/*  ) : null}*/}
            <div className={styles['input_warp']}>
              <input
                ref={inputRef}
                onFocus={() => {
                  setShowMoreFun(false)
                }}
                placeholder={t('enter_placeholder')}
                // value={text}
                onChange={handleChange}
              />
            </div>
            {renderBtn()}
          </div>
          <div className={`${styles['more_fun_container']} ${showMoreFun === true ? styles['-active'] : ''}`}>
            {
              isIMConnect && showMoreFun && moreFunList && moreFunList.length && moreFunList.map((item, index) => {
                return <MoreFun key={index}
                                icon={item.icon}
                                title={item.title}
                                callback={item.callback}
                                isFile={item.isFile}
                                accept={item.accept}
                                jumpUrl={item.jumpUrl}/>
              })
            }
          </div>
        </div>

      </form>
    )

  }

  const renderRelateRobot = list => {
    // console.log('list',list);
    return list.map((value, index) => {
      return (
        <span onClick={() => {
          changeRobot(value.robot_id)
        }} key={index} className={styles['item']}>
          {index < 2 ? <svg className={`icon`} aria-hidden="true">
            <use xlinkHref={`#icon-zhike-remen`}/>
          </svg> : null}
          <span>
            {value?.nickname}
          </span>
        </span>
      )
    })
  };


  const render = () => {
    return (
      <div style={{position: 'relative'}}>
        {eventSourceState && eventSourceState === 1 ? (
          <div style={{textAlign: 'center'}} className={styles['stop-question-btn']} onClick={stopQuestion}>
            <a>停止问答</a>
          </div>) : null}
        {(isInputing && isIMConnect) ?
          <div className={styles['inputing-container']}>对方正在输入中......</div> : null}
        {
          info.relateRobots && Array.isArray(info.relateRobots) && info.relateRobots.length ?
            isSDK ?
              <div className={styles['isSDK-relative-robot-wrap-container']}>
                <Slider {...settings}>
                  {renderRelateRobot(info.relateRobots)}
                </Slider>
              </div> : <div className={styles['relative-robot-wrap-container']}>
                {renderRelateRobot(info.relateRobots)}
              </div>
            : null
        }

        {canCollect() ?
          <div className={styles['collect-btn']} style={{background: info.btnColor}} onClick={collectClues}>
            快速申请
          </div> : null}

        {
          showEntranceForm ?
            <div className={styles['collect-btn']} style={{background: info.btnColor}}  onClick={()=>{openForm(entranceFormInfo)}}>
              {entranceFormInfo?.entrance?.name}
            </div> : null
        }

        <div className={`${styles.kf_footer} ${isIMConnect ? '' : styles['im-not-connect']} `} onKeyPress={handleEnter}>


          {info.customer_mode === 2 && !isIMConnect ? <div className={`${styles['footer-mask-container']}`}>

            <div className={styles['connect-btn']} onClick={toIM}>重新连接</div>
          </div> : null}

          {showNewInformationTips && newInformationNumber > 0 ?
            <div className={styles['new-information']} style={{color: info.color}} onClick={scrollToBottom}>
              <svg className={styles['new-information-icon']} aria-hidden="true" style={{fill: info.color}}>
                <use xlinkHref={`#icon-zhike-double-arrow-down`}/>
              </svg>
              {newInformationNumber}条新消息
            </div> : null}


          {
            isIMConnect ? <div className={styles['icon-container']}>
              <svg className={`icon ${styles.icon}`}
                   onClick={() => {
                     setShowEmoji(!showEmoji)
                   }}
                   aria-hidden="true"
              >
                <use xlinkHref={`#icon-zhike-xiaolian`}/>
              </svg>


              {
                moreFunList && moreFunList.length > 0 && moreFunList.map((item, index) => {
                  return <MoreFunPC key={index}
                                    icon={item.PCIcon || item.icon}
                                    callback={item.callback}
                                    isFile={item.isFile}
                                    accept={item.accept}
                                    jumpUrl={item.jumpUrl}/>
                })
              }

            </div> : null
          }

          {
            imWaitingNetWorkError ? <div className={styles['waiting-im']}>
              <span>
                网络中断导致排队异常，请点击【
              </span>
              <a onClick={toIM}>继续排队</a>
              <span>】确保您当前排位</span>
              <svg onClick={() => {
                closeImWaiting()
              }} className={`icon ${styles['close-btn']}`} aria-hidden="true">
                <use xlinkHref={`#icon-zhike-feedback-close`}/>
              </svg>
            </div> : null
          }
          {
            imWaiting && imWaiting > 0 && !imWaitingNetWorkError ? <div className={styles['waiting-im']}>
              {waitQueueInfo ? `转人工排队中，当前您排第${waitQueueInfo}位` : "正在为您转接人工客服，请稍后..."}

              <svg onClick={() => {
                closeImWaiting()
              }} className={`icon ${styles['close-btn']}`} aria-hidden="true">
                <use xlinkHref={`#icon-zhike-feedback-close`}/>
              </svg>

            </div> : null
          }

          {info.customer_mode && info.customer_mode === 3 && !isIMConnect && !imWaiting && !isBan && !isHide ?
            <div className={styles['to-im']} onClick={toIM}>
              转人工
            </div> : null}
          {
            isIMConnect && !isSDK ? <div className={styles['to-im']} onClick={closeHandle}>
              结束人工
            </div> : null
          }


          {showChange && (
            <img src="https://p5.ssl.qhimg.com/t015f4193ad2552b8ff.png" className={styles.kf_change}
                 onClick={triggerChange}/>
          )}
          {isIMConnect ?
            <div
              contentEditable="true"
              id="contentArea"
              className={styles['text-input']}
              ref={inputRef}
              onBlur={pcHandleChange}
              onInput={pcHandleChange}
            ></div> :
            <textarea
              ref={inputRef}
              id="contentArea"
              className={styles.kf_input}
              placeholder={t('enter_placeholder')}
              // value={text}
              onChange={pcHandleChange}
            ></textarea>
          }
          <div className={styles['suggest-container']}>

            {
              getInputText() && suggestData.map((item, index) => {
                return <div key={index} className={styles['item']} onClick={() => {
                  sendSuggest(item.question, item.record_id)
                }}>
                  <span dangerouslySetInnerHTML={{__html: item.question_format}}></span>
                </div>
              })
            }


          </div>

          <div className={styles.kf_submit} onClick={handleClick} style={{background: info.btnColor}}>{t('send')}</div>


          {showEmoji ?
            <div style={{
              position: 'absolute',
              top: '-190px',
              left: '0',
            }} ref={emojiRef}>
              <Picker
                sheetUrl={"http://p0.qhimg.com/t018e497db37fca6bc9.png"}
                // custom={{
                //
                // }}
                exclude={['search',
                  'recent', 'nature', 'foods',
                  'activity',
                  'places',
                  'objects',
                  'symbols',
                  'flags',
                  'custom']}
                style={{
                  width: '100%',
                  height: '190px',
                  overflow: 'hidden'
                }}
                sheetSize={20}
                set={'apple'}
                native={false}
                i18n={emojiI18n}
                onSelect={(emoji) => {
                  // let result = inputRef.current.innerHTML;
                  // result += `[${emojiMap[emoji.unified.toLocaleUpperCase()].name}]`;
                  // inputRef.current.innerHTML = '';
                  // inputRef.current && inputRef.current.focus && inputRef.current.focus();
                  // inputRef.current.innerHTML = result;
                  try{
                    inputRef.current && inputRef.current.focus && inputRef.current.focus();
                    const range = document.createRange();
                    const sel = window.getSelection();
                    console.log(document.activeElement);
                    const tailIndex = inputRef.current.childNodes.length;
                    const tailNode = inputRef.current.childNodes[tailIndex - 1];
                    if(tailNode){
                      range.setStart(tailNode, tailNode.length);
                      range.collapse(true);
                      sel.removeAllRanges();
                      sel.addRange(range);
                    }
                  }catch (e){

                  }finally {
                    document.execCommand('insertHTML', false, `[${emojiMap[emoji.unified.toLocaleUpperCase()].name}]`);
                  }


                  // let text = getInputText();
                  // setInputText(`${text}[${emojiMap[emoji.unified.toLocaleUpperCase()].name}]`)
                  // setText(pre => {
                  //   let result = pre;
                  //   console.log(pre);
                  //   if (typeof emojiMap[emoji.unified.toLocaleUpperCase()] != "undefined") {
                  //     result = pre + `[${emojiMap[emoji.unified.toLocaleUpperCase()].name}]`;
                  //   }
                  //   inputRef.current.innerHTML = result;
                  //   return result;
                  // })
                  setShowEmoji(false);
                  setInputStatus(true);
                }}
                emojisToShowFilter={emoji => {
                  if (typeof emojiMap[emoji.unified] != "undefined") {
                    return true;
                  } else {
                    return false;
                  }
                }}
              />
            </div>
            : null}
        </div>
      </div>
    )
  }


  return (
    isMobile ? renderMobile() : render()
  )
}