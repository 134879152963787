// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".H5-header-container--2i-pk {\n  display: flex;\n  padding: 0.16rem;\n  justify-content: center;\n  align-items: center;\n  align-self: stretch;\n  flex-direction: column;\n  flex-wrap: nowrap;\n}\n.H5-header-container--2i-pk .title--1CSjH {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 1;\n  align-self: stretch;\n  overflow: hidden;\n  color: #1D2531;\n  text-align: center;\n  text-overflow: ellipsis;\n  /* F17/Bold */\n  font-family: \"PingFang SC\";\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n}\n.H5-header-container--2i-pk .desc--pyAlU {\n  overflow: hidden;\n  color: #77797E;\n  text-overflow: ellipsis;\n  /* F12/Regular */\n  font-family: \"PingFang SC\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n}\n.H5-footer-container--2LU72 {\n  padding: 0.12rem 0.16rem;\n}\n.H5-footer-container--2LU72 .btn--204bs {\n  display: flex;\n  height: 0.48rem;\n  min-width: 1rem;\n  padding: 0 0.16rem;\n  justify-content: center;\n  align-items: center;\n  gap: 0.08rem;\n  align-self: stretch;\n  border-radius: 0.04rem;\n  background: linear-gradient(79deg, #1560FF 13.76%, #3EC6FF 107.83%);\n  color: var(----, #FFF);\n  font-family: \"PingFang SC\";\n  font-size: 0.17rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 0.24rem;\n  /* 141.176% */\n}\n", ""]);
// Exports
exports.locals = {
	"H5-header-container": "H5-header-container--2i-pk",
	"title": "title--1CSjH",
	"desc": "desc--pyAlU",
	"H5-footer-container": "H5-footer-container--2LU72",
	"btn": "btn--204bs"
};
module.exports = exports;
