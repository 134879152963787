import React from 'react';
import styles from './index.less';
import {toast} from "../../static/util/utils";

const tostTip = toast();
const ChatFileItem = props => {

  const {
    file = {},
    loading,
    isMobile = false
  } = props;

  const convertFileName = fileName => {
    if (fileName) {
      if (fileName.length > 16) {
        return `${fileName.substring(0, 7)}...${fileName.substring(fileName.length - 7, fileName.length)}`;
      } else {
        return fileName;
      }
    }
  }

  const bytesToSize = (bytes) => {
    if (bytes) {
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
  }


  const render = () => {
    return <div className={styles['container']}>

      <div className={styles['inner']}>
        <div className={styles['type']}>
          <svg className={`icon ${styles['icon']}`}
               aria-hidden="true">
            <use
              xlinkHref={`#icon-zhike-cloud`}/>
          </svg>
        </div>
        <div className={styles['content']}>

          <div className={styles['title']}>
            {convertFileName(file.name || file.fileName)}
          </div>
          <div className={styles['size']}>
            {bytesToSize(file.size || file.fileSize)}
          </div>

          <div className={styles['summary']}>
            {loading ? null : <a
              href={file.fileUrl}
              title="下载" target="_blank" download={file.name || file.fileName}>下载</a>}
            {loading === 'loading' ? <span>上传中...</span> : (loading === 'error' ? <span>上传失败</span> : null)}

            {loading ? null : <a style={{marginLeft: 5}}
                                 onClick={() => {

                                   let textareaElement = document.createElement('textarea');
                                   textareaElement.setAttribute('readonly', 'readonly'); // 防止弹出输入编辑
                                   textareaElement.value = file.fileUrl; //将需要拷贝的内容设置到textarea元素中。
                                   document.body.appendChild(textareaElement);
                                   textareaElement.select(); //选中textarea元素，以备调用系统copy函数复制其中的内容。
                                   let isSuccess = document.execCommand('copy'); //调用操作系统复制api，返回是否复制成功。true或false！
                                   document.body.removeChild(textareaElement)
                                   tostTip('复制成功');
                                   return;
                                   // debugger;
                                   // urlRef.current.value =
                                   //   file.fileUrl;
                                   // urlRef.current.select();
                                   // let result = document.execCommand('Copy');
                                   // alert(result);
                                   // toast('复制成功')
                                 }}
            >复制下载地址</a>}


          </div>

        </div>
      </div>
    </div>
  }

  const renderMobile = () => {
    return <div className={styles['m-container']}>
      <a href={file.fileUrl}>


        <div className={styles['inner']}>
          <div className={styles['type']}>
            <svg className={`icon ${styles['icon']}`}
                 aria-hidden="true">
              <use
                xlinkHref={`#icon-zhike-cloud`}/>
            </svg>
          </div>

          <div className={styles['content']}>
            <div className={styles['title']}>
              {convertFileName(file.name || file.fileName)}
            </div>
            <div className={styles['size']}>
              {bytesToSize(file.size || file.fileSize)}
            </div>
          </div>

        </div>

      </a>
      <div className={styles['download-container']}>
        {loading ? null : <a
          href={file.fileUrl}
          title="下载" target="_blank" download={file.name || file.fileName}>下载</a>}
        {loading === 'loading' ? <span>上传中...</span> : (loading === 'error' ? <span>上传失败</span> : null)}

        <a style={{marginLeft: '.1rem'}}
           onClick={() => {

             let textareaElement = document.createElement('textarea');
             textareaElement.setAttribute('readonly', 'readonly'); // 防止弹出输入编辑
             textareaElement.value = file.fileUrl; //将需要拷贝的内容设置到textarea元素中。
             document.body.appendChild(textareaElement);
             textareaElement.select(); //选中textarea元素，以备调用系统copy函数复制其中的内容。
             let isSuccess = document.execCommand('copy'); //调用操作系统复制api，返回是否复制成功。true或false！
             document.body.removeChild(textareaElement)
             tostTip('复制成功');
             return;
             // debugger;
             // urlRef.current.value =
             //   file.fileUrl;
             // urlRef.current.select();
             // let result = document.execCommand('Copy');
             // alert(result);
             // toast('复制成功')
           }}
        >复制下载地址</a>

      </div>
    </div>
  }


  return (
    isMobile ? renderMobile() : render()
  )
}

export default ChatFileItem;
