import 'babel-polyfill'
// import 'core-js/es/set'
// import 'core-js/es/map'
import './index.less'
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import App from './core/app'
import {isMobile, generateUTag, isTuiTui} from './static/util/utils'
import Rem from './static/util/rem'
import './locales/i18n';
import 'core-js/actual';
import 'antd-mobile/es/global'

require('jquery');
require('./lib/tuitui-websdk');

//font-class 与 symbol并存仅多10kb+
import './iconfont/iconfont.css';
import './iconfont/iconfont';

// import 'antd/dist/antd.css';


import OrderReply from './page/order/orderReply';

const rootElement = document.getElementById("root");

generateUTag();

import moment from 'moment-timezone';
moment.tz.setDefault('Aisa/Shanghai');


import * as Sentry from "@sentry/react";

try {
  let dsn = `https://d773cbd80f7901f7cd51290d9b8095ce@sentry.help.360.cn/6`;
  switch (process.env.NODE_ENV){
    case "development":
      dsn = `https://d773cbd80f7901f7cd51290d9b8095ce@sentry.help.360.cn/6`;
      break;
    case "none":
      dsn = `https://d773cbd80f7901f7cd51290d9b8095ce@sentry.help.360.cn/6`;
      break;
    default:
      dsn = `https://8cc512384805e37ebd86a972d3ca6564@sentry.help.360.cn/7`;
      break;
  }
  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}catch (e){

}



//判断是否为推推环境
const judgeQH = () => {
  return new Promise((resolve, reject) => {
    if (isTuiTui() && window?.qh) {
      qh.config({
        jsApiList: ['login']
      })

      qh.ready(() => {
        qh.login({
          appId: '7652669648862278',
          success: function (res) {
            resolve(res);
          },
          fail: () => {
            resolve(null);
          }
        })
      })
    } else {
      resolve(null);
    }
  })
}

judgeQH().then(res => {

  if (res) {
    res.zk_src = 'zk';
    delete res.appid;
    window._encrypt_info = res;
  }

  if (isMobile()) {
    let rem = new Rem({
      designWidth: 375,
      rootValue: 100
    });
    rem.ready(function () {
      // html根元素 font-size 设置完成后，执行该回调方法
      ReactDOM.render(
        <BrowserRouter>
          <Routes>
            <Route path="/order/reply/:id" element={<OrderReply isMobile={true} />} />
            <Route path="*" element={<App isMobile={true} />}  />
          </Routes>
        </BrowserRouter>,
        rootElement
      );
    });
  } else {
    // $('body').css({
    //   minWidth: '800px'
    // })
    ReactDOM.render(
      <BrowserRouter>
        <Routes>
          <Route path="/order/reply/:id" element={<OrderReply />} />
          <Route path="*" element={<App />}  />
        </Routes>

      </BrowserRouter>,
      rootElement
    );
  }

});



