import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import styles from './index.less';

import schema from './testSchema';
import FormRender, {useForm} from "form-render";
import {ConfigProvider,Cascader} from "antd";

const Render = React.forwardRef((props,ref) => {
  const {
    formData,
    onFinishHandle = () => {

    }
  } = props;
  const form = useForm();
  const onFinish = (formData, errors) => {
    console.log('formData:', formData, 'errors', errors);
    onFinishHandle(formData);
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.submit();
    },
  }));
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 16,
          }
        }
      }}
    >
      <div>
        <FormRender widgets={{ cascader: Cascader }} form={form} schema={formData} onFinish={onFinish}/>
      </div>
    </ConfigProvider>
  );
});

export default Render;