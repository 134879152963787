class Login {
  loginCloseObserver = null

  constructor({
                closeHandler = () => {
                }
              } = {}) {
    this.closeHandler = closeHandler;
  }

  closeHandler() {
  }

  init({
      hideCloseBtn = false,
       }={}) {
    let __window = window;
    return new Promise(resolve => {
      const _this = this;
      try {
        const script = document.createElement('script');
        script.src = `//s.ssl.qhimg.com/quc/quc7.js?_t=${new Date().getTime()}`;
        script.onerror = function (e) {
          resolve(e);
          // 业务 src： 若新业务一定要到首页先申请src
        }
        script.onload = function () {
          try{
            let __QHPass =  __window.QHPass;
            __QHPass && __QHPass.init({
              src: 'pcw_zhike',
              signIn: {
                "signUpTip": "",
                panelCloseHandler: () => {
                  console.log('close');
                  _this.closeHandler && _this.closeHandler();
                  _this.loginCloseObserver && _this.loginCloseObserver();
                }
              },
              signUp: {
                panelCloseHandler: () => {
                  console.log('close');
                  _this.closeHandler && _this.closeHandler();
                  _this.loginCloseObserver && _this.loginCloseObserver();
                }
              },
            });
            __QHPass && __QHPass.events.on('afterShow.signIn', function(){
              // alert('准备显示登录窗口');
              try{
                let container = document.querySelector('body > div.quc-panel.quc-wrapper.quc-panel-small.quc-panel-dialog.quc-panel-popup > div.quc-panel-hd > a');
                hideCloseBtn && container.remove();
                // console.log(container);
              }catch (e){
              }

            });
          }catch (e){

          }
          resolve();
        }
        document.body.appendChild(script);
      } catch (e) {
        resolve(e);
      }
    })
  }

  getUserData() {
    let __window = window;
    return new Promise(resolve => {
      try {
        let __QHPass = __window.QHPass;
        __QHPass && __QHPass.getUserInfo(function (data) {
          console.log(data);
          resolve(data);
        }, function (error) {
          console.log(error);
          resolve();
        });
      } catch (e) {
        resolve();
      }
    });
  }

  async login() {
    let __window = window;
    return new Promise(async resolve => {
      try {
        let __QHPass = __window.QHPass;
        __QHPass && __QHPass.utils && __QHPass.utils.support && (__QHPass.utils.support.fixed = true);
        let data = await this.getUserData();
        if (data) {
          return resolve(data);
        }
        this.loginCloseObserver = () => {
          return resolve();
        }
        __QHPass &&
        __QHPass.signIn(
          async function (params) {
            // debugger;
            if (params) {
              console.log(params);
              return resolve(params);
            }
          },
          function (e) {
            console.log(e);
            return resolve();
          },
        );
      }catch (e){
        resolve();
      }

    });
  }
}

export default Login;