// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".side-bar--312mC {\n  position: relative;\n  width: 400px;\n  height: 80%;\n  background: #F2F7FF;\n  margin-left: 20px;\n  flex-wrap: nowrap;\n}\n.side-bar--312mC .header--33CZH {\n  position: absolute;\n  width: 100%;\n  height: 62px;\n  background: #384459;\n  line-height: 62px;\n  text-align: left;\n  padding: 0 30px;\n}\n.side-bar--312mC .header--33CZH .title--qbRxu {\n  font-size: 14px;\n  font-family: PingFangSC-Medium, PingFang SC;\n  font-weight: 500;\n  color: #FFFFFF;\n  line-height: 20px;\n}\n.side-bar--312mC .content--2U344 {\n  width: 100%;\n  height: 100%;\n  padding-top: 62px;\n}\n.side-bar--312mC .content--2U344 .container--3Srha {\n  width: 100%;\n  max-height: 100%;\n  height: 100%;\n  overflow-y: auto;\n  font-size: 0;\n}\n.side-bar--312mC .content--2U344 .container--3Srha .item--1Ijn5 {\n  width: 100%;\n  height: 214px;\n  margin: 0;\n}\n.side-bar--312mC .content--2U344 .container--3Srha::-webkit-scrollbar {\n  width: 6px;\n  height: 1px;\n}\n.side-bar--312mC .content--2U344 .container--3Srha::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background: #cacaca;\n}\n.side-bar--312mC .content--2U344 .container--3Srha::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background: #d8d8d8;\n}\n", ""]);
// Exports
exports.locals = {
	"side-bar": "side-bar--312mC",
	"header": "header--33CZH",
	"title": "title--qbRxu",
	"content": "content--2U344",
	"container": "container--3Srha",
	"item": "item--1Ijn5"
};
module.exports = exports;
