import React, {useContext, useRef} from 'react';
import {Button, ConfigProvider, Modal} from "antd";
import FormRender from "../FormRender";
import {css} from '@emotion/css';

const PCModal = (props) => {
  const {name,description,formData,onFinishHandle=()=>{},onCloseHandle=()=>{}} = props;
  const formRef = useRef(null);
  const {getPrefixCls} = useContext(ConfigProvider.ConfigContext);
  const rootPrefixCls = getPrefixCls();
  const linearGradientButton = css`
      &.${rootPrefixCls}-btn-primary:not([disabled]):not(.${rootPrefixCls}-btn-dangerous) {
          border-width: 0;

          > span {
              position: relative;
          }

          &::before {
              content: '';
              background: linear-gradient(93deg, #1560FF 2.37%, #3EC6FF 109.25%);
              position: absolute;
              inset: 0;
              opacity: 1;
              transition: all 0.3s;
              border-radius: inherit;
          }

          &:hover::before {
              opacity: 0;
          }
      }
  `;

  return <ConfigProvider
    modal={{
      styles: {
        mask: {},
        body: {
          padding: '12px 24px',
          maxHeight:'360px',
          overflow:'auto',
        },
        content: {
          padding: 0,
        },
        header: {
          padding: '12px 24px',
          paddingBottom: description?'4px':'12px',
          textAlign: 'center',
        },
        footer: {
          borderRadius: '0 0 8px 8px',
          padding: '12px 24px',
        }
      }
    }}
    theme={{
      token: {
        borderRadius: 4,
      },
      components: {
        Modal: {
          // padding:0,
          contentBg: '#F7F9FA',
          headerBg: '#FFFFFF',
          borderRadiusLG: 8,
          footerBg: '#FFFFFF',
          // paddingLG:0,

        }
      }
    }}
    button={{
      className: linearGradientButton,
    }}
  >
    <Modal
      width={420}
      title={<div>
        <div style={{
          overflow: 'hidden',
          color: '#1D2531',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          fontFamily: "PingFang SC",
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px'
        }}>
          {name}
        </div>
        <div style={{
          overflow: 'hidden',
          color: '#77797E',
          textOverflow: 'ellipsis',
          fontFamily: "PingFang SC",
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          whiteSpace: 'nowrap',
        }}
             title={description}
        >
          {description}
        </div>
      </div>}
      centered
      open={true}
      footer={<div>
        <Button type="primary" block onClick={()=>{ formRef?.current?.submit()}}>
          提交
        </Button>
      </div>}
      onCancel={onCloseHandle}
    >
      <FormRender onFinishHandle={onFinishHandle} ref={formRef} formData={formData}/>
    </Modal>
  </ConfigProvider>
}

export default PCModal;