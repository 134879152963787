import React, {useEffect, useState} from "react";
import styles from './index.less'
import {muteStatus} from "../../static/util/store";
import Marquee from "react-fast-marquee";

export default function Header(props) {
  const {
    info, isMobile = false, hideHeader, fromSDK = false,
    miniHandle = () => {
    },
    closeHandle = () => {

    },
    mouseHandle = () => {

    },
    isIMConnect = false,
    noticeContent,
    userCloseSession = false,
  } = props

  const [mute, setMute] = useState(false);

  useEffect(() => {
    let temp = muteStatus();
    setMute(temp);
  }, []);

  function back() {
    history.go(-1)
  }

  const muteHandle = () => {
    muteStatus(!mute);
    setMute(!mute);
  }

  if (isMobile) {
    // return (
    //   <div>
    //     <NavBar
    //       mode="light"
    //       icon={<Icon type="left" />}
    //       onLeftClick={back}
    //     >{info.nickName}</NavBar>
    //   </div>
    // )
    return (
      <>
        {hideHeader ? null : <div className={styles.mkf_header}>
          <i className={`icon-zhike icon-zhike-go-back ${styles['go_back_btn']}`} onClick={back}/>
          <div>{info.nickName}</div>
        </div>}

        {noticeContent ? <div className={styles['mkf_notice-container']}>
          <svg
            onClick={miniHandle}
            style={{marginRight: 8}}
            className={styles['icon']}
            aria-hidden="true"
          >
            <use xlinkHref={`#icon-zhike-notice-fill`}/>
          </svg>
          <div className={styles['content']}>
            <Marquee speed={20}>
              {noticeContent}
            </Marquee>
          </div>
        </div> : null}
      </>
    )
  }
  // console.log(info.color)
  return (
    <div className={styles.kf_header} style={{background: info.color}}
         onMouseDown={mouseHandle} onMouseUp={mouseHandle}>
      <div className={styles['logo-container']}>
        <img src={info.url || "https://p3.ssl.qhimg.com/t010a6232b25737b10d.png"}/>
      </div>
      <div className={styles['header-name']}>{info.nickName}</div>

      <div className={styles['btn-container']}>
        <svg
          style={{marginRight: 8}}
          className={styles['icon']}
          aria-hidden="true"
          onClick={muteHandle}
        >
          <use xlinkHref={`#icon-zhike-${mute ? 'mute' : 'volume'}`}/>
        </svg>
        {fromSDK ? <svg
          onClick={miniHandle}
          style={{marginRight: 8}}
          className={styles['icon']}
          aria-hidden="true"
        >
          <use xlinkHref={`#icon-zhike-minus-bold-copy`}/>
        </svg> : null}
        {fromSDK ? <svg
          onClick={closeHandle}
          className={styles['icon']}
          aria-hidden="true"
        >
          <use xlinkHref={`#icon-zhike-close-bold`}/>
        </svg> : null}
      </div>

      {noticeContent ? <div className={styles['notice-container']}>
        <svg
          onClick={miniHandle}
          style={{marginRight: 8}}
          className={styles['icon']}
          aria-hidden="true"
        >
          <use xlinkHref={`#icon-zhike-notice-fill`}/>
        </svg>
        <div className={styles['content']}>
          <Marquee speed={20}>
            {noticeContent}
          </Marquee>
        </div>
      </div> : null}
    </div>
  )
}