import React, {useState} from 'react';
import styles from './index.less'

/**
 * @param {function} props.tigger 发送按钮的回调函数
 * @param {boolean} props.isMobile
 */
export default function Footer(props) {
  const {
    triggerSend,
    triggerChange = () => {
    },
    isMobile = false,
    showChange = false
  } = props
  const [text, setText] = useState('')

  // 发送数据 + 移动端回车提交
  function handleClick(e) {
    console.log(e);

    if (e) {
      e.preventDefault();
    }
    if (triggerSend) {
      triggerSend(text)
    }
    setText('')
  }

  function handleChange(e) {
    setText(e.target.value)
  }

  // pc回车提交
  function handleEnter(e) {
    if (e.charCode === 13) {
      handleClick(e)
    }
  }

  if (isMobile) {
    return (
      <form action="" className={styles.mkf_footer} onSubmit={handleClick}>
        {showChange && (
          <img src="https://p5.ssl.qhimg.com/t01cce4f4e570dab679.png" className={styles.mkf_change}
               onClick={triggerChange}/>
        )}
        <input
          placeholder="请输入您的问题"
          value={text}
          onChange={handleChange}
        />
        <span onClick={handleClick}>发送</span>
      </form>
    )
  }
  return (
    <div className={styles.kf_footer} onKeyPress={handleEnter}>

      <textarea
        className={styles.kf_input}
        placeholder="请输入您的问题"
        style={{height: '160px', width: '100%'}}
        value={text}
        onChange={handleChange}
      ></textarea>
      <div className={`${styles.kf_submit} ${text != '' ? styles['-active'] : ''}`} onClick={handleClick}>发送</div>
    </div>
  )
}