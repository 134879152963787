import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import styles from './index.less';
import {Button, Dialog, Checkbox, Switch,Picker,Selector} from 'antd-mobile';
import schema from './testSchema';
import FormRender, {useForm} from "form-render-mobile";

const H5FormRender = React.forwardRef((props,ref) => {
  const {
    formData,
    onFinishHandle = () => {
    }
  } = props;
  const [ownFormData, setOwnFormData] = useState(null);
  useEffect(()=>{
    if(formData){
      let result = convertData(formData);
      setOwnFormData(result);
      console.log('H5 render form data',formData);
    }
  },[formData]);
  const form = useForm();
  const onFinish = (formData, errors) => {
    console.log('formData:', formData, 'errors', errors);
    onFinishHandle(formData);
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.submit();
    },
  }));

  const convertData = data=>{
    let result = {...data};
    try{
      let properties = result.properties;
      Object.keys(properties).map(key=>{
        let item = properties[key];
        if(!item.widget){
          console.log(item);
          if(item.type === 'string'){
            switch (item.format){
              case "textarea":
                item.widget = 'textArea';
                delete item.format;
                return;
              case "date":
                item.widget = 'datePicker';
                delete item.format;
                return;
              default:
                item.widget = 'input';
                delete item.format;
                return;
            }
          }
          if(item.type === 'number'){
            switch (item.format){
              default:
                item.widget = 'stepper';
                delete item.format;
                return;
            }
          }
        }else{
          switch (item.widget){
            case 'select':
              item.widget = 'picker';
              delete item.format;
              return;
            case "checkboxes":
            case "multiSelect":
              item.widget = 'selector';
              item.props = {...item.props,multiple: true,}
              delete item.format;
              return;
          }
        }

      })
    }catch (e){

    }
    return result;
  }
  return (
    <div style={{flex:1,overflow:'auto'}}>
      <FormRender className={styles['h5-form-render-container']} widgets={{checkbox:Checkbox,selector:Selector}} form={form} schema={ownFormData} onFinish={onFinish}/>
    </div>
  );
});

export default H5FormRender;