import {useState, useRef, useEffect} from 'react'
import * as Sentry from "@sentry/react";
const useEventSource = () => {
  const xhr = useRef(null);
  const eventSource = useRef(null);
  const [eventSourceData, setEventSourceData] = useState(null);
  const signal = useRef(null);
  const fetchRes = useRef(null);
  // const [state, setState] = useState(0);//0:未开始,1:传送中,2:发生错误,
  const state = useRef(0);

  const createEventSource = async ({url, method = 'GET', options, id, questionId,stringToSend} = {}) => {
    console.log(id);
    try {
      if (!fetchRes?.current && url) {
        const controller = new AbortController();
        signal.current = controller.signal;
        // setState(1);

        const res = await fetch(url, {...options, signal: signal.current});

        setStateCurrent(1);
        // state && (state.current = 1);
        if(res?.status !== 200){
          throw new Error();
        }
        console.log(res);
        const reader = res.body?.getReader()
        if (reader === undefined) {
          let resJson = await res.json();
          if(resJson){
            setStateCurrent(0);
            // state && (state.current = 0);
            setEventSourceData({
              data: {
                resp:{data:{...resJson.data}},
                data: resJson.data?.answer,
                id: resJson.data?.id,
                answer_type: resJson?.data?.answer_type,
                is_rag_stop:resJson?.data?.is_rag_stop,
              }, id,stringToSend
            });
            return;
          }
          throw new Error('接口请求失败')
        }
        let buffer = ''
        let times = 0;
        let times2 = 0;
        while (true) {
          if (state?.current !== 1) {
            break;
          }
          // console.log(111111111);
          // if (times > 20) {
          //   break;
          // }
          const {done, value} = await reader.read()
          if (done) {
            reader.releaseLock();
            // setState(0);
            setStateCurrent(0);
            // state && (state.current = 0);
            setEventSourceData({
              data:null
            });
            break
          }
          const textChunk = new TextDecoder().decode(value);
          try {
            let resData = JSON.parse(textChunk);
            // console.log(resData);
            if (resData) {
              if (resData && resData?.errno === 0 && resData.data) {
                // setState(0);
                setStateCurrent(0);
                // state && (state.current = 0);
                setEventSourceData({
                  data: {
                    resp:{data:{...resData.data}},
                    data: resData.data?.answer,
                    id: resData.data?.id,
                    answer_type: resData?.data?.answer_type,
                    is_rag_stop:resData?.data?.is_rag_stop,
                  }, id,stringToSend
                });
                return;
              }
              // setState(2);
              setStateCurrent(-2);
              // state && (state.current = 2);
              if (resData.errmsg) {
                setEventSourceData({data: {data: resData.errmsg}, id,stringToSend});
              } else {
                setEventSourceData({data: {data: '操作频繁，请稍后再试！'}, id,stringToSend});
              }
              return;
            }
          } catch (e) {
            // console.log('parse error ',e ,textChunk);
          }
          // console.log(textChunk, '\n');
          buffer += textChunk;
          let match = new RegExp('(?:data:).+(?=\n)', 'g');
          let matchArr = Array.from(textChunk.matchAll(match));
          // console.log(matchArr);

          matchArr.map(m => {
            if (m && m[0]) {
              try {
                let str = m[0].replace(/(?:data:)/g, "");
                let obj = JSON.parse(str);
                if(typeof obj?.data === 'object'){
                  // console.log('obj',obj);
                  // state && (state.current = 0);
                  setStateCurrent(0);
                  setEventSourceData({
                    data: {
                      resp:{data:{...obj.data}},
                      data: obj.data?.answer,
                      id: obj.data?.id,
                      answer_type: obj?.data?.answer_type,
                      is_rag_stop:obj?.data?.is_rag_stop,
                    }, id,stringToSend
                  });
                  return;
                }
                setEventSourceData({data: obj, id,stringToSend});
                // console.log(obj);
              } catch (e) {
              }
            }
          });
          // while (true) {
          //   if(times2 > 20){
          //     break;
          //   }
          //   const newlineIndex = buffer.indexOf('\n');
          //   if (newlineIndex === -1) {
          //     break; // No complete line found in the buffer, read more data.
          //   }
          //   const line = buffer.substring(0, newlineIndex);
          //   buffer = buffer.substring(newlineIndex + 1); // Exclude the newline character
          //   const dataStr = line.slice(line.indexOf('data:') + 5)
          //   if (dataStr === '' || dataStr.trim() == 'done' || (/^ng/).test(dataStr) || (/^ping/).test(dataStr)) {
          //     continue
          //   }
          //   console.log(dataStr);
          // }
        }
      }
      // if (!xhr.current && url) {
      //   return;
      //   xhr.current = new XMLHttpRequest();
      //   xhr.current.open(method, url, true);
      //   // xhr.current.setRequestHeader("Content-type", "application/json;charset=UTF-8"),
      //   xhr.current.setRequestHeader("Content-Type", "text/event-stream");
      //   xhr.current.onreadystatechange = () => {
      //     if (xhr.current.readyState === 3) {
      //       // 将数据添加到文本框中
      //       console.log('xhr.responseText :>> ', xhr.current.responseText);
      //     }
      //   }
      //   xhr.current.send(options?.body);
      // }
      // if (!eventSource.current && url) {
      //   return;
      //   const controller = new AbortController();
      //   signal.current = controller.signal;
      //   let res = await fetchEventSource(
      //     url, {
      //       ...options,
      //       signal: signal.current,
      //       onerror(err) {
      //         console.log(err);
      //         throw new Error(err);
      //       },
      //       onmessage(msg) {
      //         console.log('msg', msg);
      //         setEventSourceData(msg);
      //       },
      //       async onopen(response) {
      //         console.log('open', response);
      //         if (response.ok) {
      //           return; // everything's good
      //         } else {
      //           throw new Error();
      //         }
      //       },
      //       onclose() {
      //         console.log('close');
      //       }
      //     }
      //   )
      //   console.log(22222222, res);
      //   return res;
      //   // eventSource.current = new EventSource(url,);
      //   // setTimeout(()=>{
      //   //   setEventSourceData(1);
      //   // },1000);
      //   // return eventSource.current;
      // }
    } catch (e) {
      console.log(e);
      // setState(2);
      // state && (state.current = 2);
      setStateCurrent(2);
      setEventSourceData({data: {data: '当前咨询较多，请稍后再试'}, id, questionId,stringToSend});

      try{
        Sentry.captureException(e);
      }catch (e){

      }
    }

    return null;
  }

  const setStateCurrent = (s)=>{
    closeTimeout  && clearTimeout(closeTimeout);
    state && (state.current = s);
  }

  let closeTimeout = null;
  const closeEventSource = () => {
    try {
      closeTimeout  && clearTimeout(closeTimeout);
      closeTimeout = setTimeout(()=>{
        state && (state.current = 0);
      },500);

      // if (signal && signal.current) {
      //   signal.current.abort();
      //   // setState(0);
      //   state && (state.current = 0);
      //   fetchRes.current = null;
      //   signal.current = null;
      //
      // }
    } catch (e) {

    }
  }
  return {
    createEventSource,
    eventSourceData,
    closeEventSource,
    eventSourceState: state?.current,
  }
}
export default useEventSource
