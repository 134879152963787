import axios from 'axios';
import {getUTag, generateWid, uuid} from './utils'
import queryString from "query-string";

const searchParams = queryString.parse(location.search);
const {rid = '', code = '', appid = ''} = searchParams;

const idHeader = () => {
  return {uTag: getUTag(), wid: `${generateWid()}`, rid}
};

export const baseURL = '/api';

window._time_difference = 0;


const uploadRequestZK = (url, options) => {
  const param = new FormData();
  param.append('file', options.body.file);
  param.append('serviceToken', options.body.serviceToken);
  const config = {
    headers: {'Content-Type': 'multipart/form-data', ...idHeader()},
    withCredentials: true,
  };

  return axios.post(url, param, config).then(response => {
    const {status, data, headers} = response;
    const {date, timestamp} = headers;
    try {
      window._time_difference = parseInt(timestamp ? timestamp : (date ? new Date(date).getTime() : new Date().getTime())) - new Date().getTime();
    } catch (e) {
    }
    // console.log(new Date(date).getTime(), timestamp);
    if (status >= 200 && status < 300) {
      return {...data, date: timestamp ? timestamp : (date ? new Date(date).getTime() : '')};
    }
    const error = {
      code: 0,
      message: '服务器发生错误',
    };

    if (status === 504) {
      error.message = '未连接到服务器';
    } else if (typeof (data) === 'string') {
      error.message = data;
    } else if (data) {
      const {
        error: {message, code},
      } = data;
      error.message = message;
      error.code = code;
    } else if (status >= 400 && status < 500) {
      error.message = '请求发生错误';
    }

    return {error, status};
  });
}

const uploadRequest = (url, options) => {
  const param = new FormData();
  param.append('file', options.file);

  const config = {
    headers: {'Content-Type': 'multipart/form-data', ...idHeader()},
    withCredentials: true,
  };
  return axios.post(baseURL + '/' + url, param, config).then(response => {
    const {status, data} = response;
    if (status >= 200 && status < 300) {
      return data;
    }
    const error = {
      code: 0,
      message: '服务器发生错误',
    };

    if (status === 504) {
      error.message = '未连接到服务器';
    } else if (typeof (data) === 'string') {
      error.message = data;
    } else if (data) {
      const {
        error: {message, code},
      } = data;
      error.message = message;
      error.code = code;
    } else if (status >= 400 && status < 500) {
      error.message = '请求发生错误';
    }

    return {error, status};
  });
};

export default async function request(url, options) {
  let id = uuid();
  const defaultHeader = {'X-Request-Id': id};
  const opts = {
    baseURL,
    url,
    validateStatus() {
      return true;
    },
    ...options,
    withCredentials: true,
  };

  if (opts.method === 'POST' || opts.method === 'PUT') {
    defaultHeader['Content-Type'] = 'application/json; charset=utf-8';
    opts.data = opts.body;
  }

  opts.headers = {...defaultHeader, ...opts.headers, ...idHeader()};

  if (opts.file) {
    return uploadRequest(url, opts);
  }

  return axios.request(opts).then(response => {
    const {status, data, headers} = response;
    const {date, timestamp} = headers;
    try {
      window._time_difference = parseInt(timestamp ? timestamp : (date ? new Date(date).getTime() : new Date().getTime())) - new Date().getTime();
    } catch (e) {
    }

    // console.log(new Date(date).getTime(), timestamp);
    if (status >= 200 && status < 300) {
      return {...data, date: timestamp ? timestamp : (date ? new Date(date).getTime() : '')};
    }
    const error = {
      code: 0,
      message: '服务器发生错误',
    };

    if (status === 504) {
      error.message = '未连接到服务器';
    } else if (typeof (data) === 'string') {
      error.message = data;
    } else if (data) {
      const {
        error: {message, code},
      } = data;
      error.message = message;
      error.code = code;
    } else if (status >= 400 && status < 500) {
      error.message = '请求发生错误';
    }

    return {error, status};
  });
}

export {
  uploadRequestZK
}