import {operateMultiId, uuid} from "../static/util/utils";
import {GUESS_LIMIT} from "../static/const";

let noAnswer = "没有答案哟~";
export async function answerController({resp,info,machineTransition,questionMap,countItem,setCurrentAutoIMItemID,countResponse,countRepeatResponse}) {

  // if (parseInt(resp.errno) !== 0) {
  //   machineTransition({
  //     type: 'init', param: {
  //       content: resp.errmsg
  //     }
  //   })
  //   return
  // }


  //为每条消息生成随机id
  const msgId = uuid();
  setCurrentAutoIMItemID(msgId);

  //命中猜你想问
  if (resp.data && resp.data.guess && resp.data.guess.length) {
    machineTransition({
      type: 'guessResponse', param: {
        content: resp.data.answer,//|| info.kfInfo.guide,
        list: [...resp.data.guess.slice(0, GUESS_LIMIT)],
        id: resp.data.id || '',
        msgId: msgId,
      }
    })
    countResponse('GuessResponse');
    countRepeatResponse(countItem);
    return;
  }

  //命中重复知识库
  if (resp.data && resp.data.repeat && resp.data.repeat.length) {
    machineTransition({
      type: 'guessResponse', param: {
        content: resp.data.answer,//|| info.kfInfo.guide,
        list: [...resp.data.repeat.slice(0, GUESS_LIMIT)],
        id: resp.data.id || '',
        msgId: msgId,
      }
    })
    // countResponse('GuessResponse');
    countRepeatResponse(countItem);
    return;
  }

  // if (!isLoginApply && resp.data && resp.data.need_login) {
  //   //如果命中登录节点或大会员体系
  //   let res = await loginControl.login();
  //   console.log(_this, _args);
  //   if (res) {
  //     send.apply(_this, [_args[0], undefined, {isLoginApply: true, resp}]);
  //     return;
  //   } else {
  //     return;
  //   }
  // }

  //多轮对话开始节点立即触发回复节点
  if (!resp?.data?.dialogue_id && resp?.data?.select_list && resp?.data?.select_list?.list?.length && resp?.data?.select_list?.type === 'dialogue_faq') {
    machineTransition({
      type: 'guessResponse', param: {
        content: resp.data.answer,//|| info.kfInfo.guide,
        list: [...resp.data.select_list?.list],
        id: resp.data.id || '',
        msgId: msgId,
      }
    })
    return;
  }

  machineTransition({
    type: 'dealResponse', param: {
      _id: resp.data?._id,
      contentList: resp.data.ext_answer,
      content: resp.data.answer || noAnswer,
      list: resp.data.recommend ? [...resp.data.recommend] : [],
      id: resp.data.id || '',
      commons: info.kfInfo.guess,
      msgId: msgId,
      typedInput: true,
    }
  });

  switch (resp?.data?.answer_type) {
    case 0:
      countResponse('UnknownResponse');
      break;
    case 1:
      countResponse('DirectlyResponse');
      break;
    case 2:
      //寒暄回复打断连续计数
      countResponse('noType');
      break;
    case 3:
      countResponse('GuessResponse');
      break;
  }
  countRepeatResponse(countItem);
}