import Login from "./login";
import {isMobile} from "../static/util/utils";

const FunList = [
  async ({rid}) => {
    if(!['d7204dc3-a36c-47f8-8c5d-b2743303b747','29829a26-5e4f-4482-bd7f-99b46b8b2128'].includes(rid)){
      return;
    };
    let loginC = new Login();
    await loginC.init({hideCloseBtn:true});
    let data = await loginC.getUserData();
    if (data) {
      return resolve(data);
    } else {
      if(isMobile()){
        window.location.href = `https://i.360.cn/login/wap?destUrl=${encodeURI(window.location.href)}`
      }else{
        let res = await loginC.login();
        if(res){
          return resolve(data);
        }
      }
    }
  }

];

export default FunList;