const emojiMap = {
  "1F642": {name: '微笑', id: 'slightly_smiling_face'},
  '1F601': {id: 'grin', name: '大笑'},
  '1F60A': {id: 'blush', name: '可爱'},
  '1F60E': {id: 'sunglasses', name: '得意'},
  '1F60D': {id: 'heart_eyes', name: '色'},
  '1F633': {id: 'flushed', name: '发呆'},
  '1F602': {id: 'joy', name: '笑哭'},
  '1F61C': {id: 'stuck_out_tongue_winking_eye', name: '调皮'},
  '1F604': {id: 'smile', name: '憨笑'},
  '1F913': {id: 'nerd_face', name: '认真'},
  '1F606': {id: 'laughing', name: '开心'},
  '1F62F': {id: 'hushed', name: '疑问'},
  '1F634': {id: 'sleeping', name: '睡'},
  '1F924': {id: 'drooling_face', name: '流口水'},
  '1F62D': {id: 'sob', name: '流泪'},
  '1F920': {id: 'face_with_cowboy_hat', name: '悠闲'},
  '1F618': {id: 'kissing_heart', name: '亲亲'},
  '1F970': {id: 'smiling_face_with_3_hearts', name: '么么哒'},
  '1F609': {id: 'wink', name: '挤眼'},
  '1F61D': {id: 'stuck_out_tongue_closed_eyes', name: '吐舌头'},
  '1F603': {id: 'smiley', name: '期待'},
  '1F605': {id: 'sweat_smile', name: '尴尬'},
  '1F61F': {id: 'worried', name: '皱眉'},
  '1F614': {id: 'pensive', name: '不理你'},
  '1F613': {id: 'sweat', name: '流汗'},
  '2639-FE0F': {id: 'white_frowning_face', name: '难过'},
  '1F632': {id: 'astonished', name: '惊讶'},
  '1F635': {id: 'dizzy_face', name: '晕'},
  '1F622': {id: 'cry', name: '可怜'},
  '1F62B': {id: 'tired_face', name: '抓狂'},
  '1F629': {id: 'weary', name: '囧'},
  '1F637': {id: 'mask', name: '生病'},
  '1F910': {id: 'zipper_mouth_face', name: '闭嘴'},
  '1F925': {id: 'lying_face', name: '撒谎'},
  '1F644': {id: 'face_with_rolling_eyes', name: '白眼'},
  '1F915': {id: 'face_with_head_bandage', name: '难受'},
  '1F914': {id: 'thinking_face', name: '思考'},
  '1F621': {id: 'rage', name: '发怒'},
  '1F611': {id: 'expressionless', name: '郁闷'},
  '1F641': {id: 'slightly_frowning_face', name: '不开心'},
  '1F620': {id: 'angry', name: '生气'},
  '1F624': {id: 'triumph', name: '发脾气'},
  '1F60F': {id: 'smirk', name: '撇嘴'},
  '1F60C': {id: 'relieved', name: '享受'},
  '1F62A': {id: 'sleepy', name: '叹气'},
  '1F922': {id: 'nauseated_face', name: '衰'},
  '1F625': {id: 'disappointed_relieved', name: '冷汗'},
  '1F607': {id: 'innocent', name: '天使'},
  '1F608': {id: 'smiling_imp', name: '恶魔'},
  '1F631': {id: 'scream', name: '惊恐'},
  '1F628': {id: 'fearful', name: '震惊'},
  '1F630': {id: 'cold_sweat', name: '担心'},
  '1F480': {id: 'skull', name: '骷髅'},
  '1F4A9': {id: 'hankey', name: '便便'},
  '1F47B': {id: 'ghost', name: '幽灵'},
  '1F91D': {id: 'handshake', name: '握手'},
  '1F44D': {id: '+1', name: '强'},
  '1F44E': {id: '-1', name: '弱'},
  '1F44C': {id: 'ok_hand', name: 'OK'},
  '1F64F': {id: 'pray', name: '保佑'},
  '270C-FE0F': {id: 'v', name: '胜利'},
  '1F973': {id: 'partying_face', name: '欢迎'},
  '1F92E': {id: 'face_vomiting', name: '呕吐'},
  '1F47D': {id: 'alien', name: '外星人'},
  '1F448': {id: 'point_left', name: '左'},
  '1F449': {id: 'point_right', name: '右'},
  '1F446': {id: 'point_up_2', name: '上'},
  '1F447': {id: 'point_down', name: '下'},
  '261D-': {id: 'FE0F point_up', name: '第一'},
  '1F44A': {id: 'facepunch', name: '拳头'},
  '1F44F': {id: 'clap', name: '鼓掌'},
  '1F4AA': {id: 'muscle', name: '强壮'},
  '1F9B6': {id: 'foot', name: '脚'},
  '1F442': {id: 'ear', name: '耳朵'},
  '1F443': {id: 'nose', name: '鼻子'},
  '1F9B7': {id: 'tooth', name: '牙'},
  '1F9B4': {id: 'bone', name: '骨头'},
  '1F440': {id: 'eyes', name: '眼睛'},
  '1F445': {id: 'tongue', name: '舌头'},
  '1F444': {id: 'lips', name: '嘴唇'},
  '1F91E': {id: 'crossed_fingers', name: '比心'},
  '1F918': {id: 'the_horns', name: '666'},
  '1F919': {id: 'call_me_hand', name: '打电话'},
  '1F466': {id: 'boy', name: '男孩'},
  '1F467': {id: 'girl', name: '女孩'},
  '1F9D2': {id: 'child', name: '孩子'},
  '1F476': {id: 'baby', name: '宝贝'},
  '1F468': {id: 'man', name: '男士'},
  '1F469': {id: 'woman', name: '女士'},
  '2764-': {id: 'FE0F heart', name: '心'},
  '1F494': {id: 'broken_heart', name: '心碎'},
  '1F498': {id: 'cupid', name: '丘比特'},
  '1F469-200D-2764-FE0F-200D-1F48B-200D-1F469': {id: 'woman-kiss-woman', name: '吻'},
  '1F469-200D-2764-FE0F-200D-1F468': {id: 'woman-heart-man', name: '热恋'},
  '2600-FE0F': {id: 'sunny', name: '太阳'},
  '1F319': {id: 'crescent_moon', name: '月亮'},
  '2B50': {id: 'star', name: '星星'},
  '1F389': {id: 'tada', name: '庆祝'},
  '1F382': {id: 'birthday', name: '生日'},
  '1F37B': {id: 'beers', name: '干杯'},
  '2615': {id: 'coffee', name: '咖啡'},
  '1F435': {id: 'monkey_face', name: '猴子'},
  '1F42E': {id: 'cow', name: '牛'},
  '1F436': {id: 'dog', name: '狗'},
  '1F98A': {id: 'fox_face', name: '狐狸'},
  '1F437': {id: 'pig', name: '猪'},
  '1F42F': {id: 'tiger', name: '老虎'},
  '1F37A': {id: 'beer', name: '啤酒'},
  '1F339': {id: 'rose', name: '玫瑰'},
  '1F33A': {id: 'hibiscus', name: '花'},
  '1F335': {id: 'cactus', name: '仙人掌'},
  '1F384': {id: 'christmas_tree', name: '圣诞树'},
  '1F34E': {id: 'apple', name: '苹果'},
  '1F34B': {id: 'lemon', name: '柠檬'},
  '1F349': {id: 'watermelon', name: '西瓜'},
  '1F34C': {id: 'banana', name: '香蕉'},
}

export default emojiMap;

export function getEmojiIdByName(name) {
  for (let key in emojiMap) {
    let item = emojiMap[key];
    if (item.name === name) {
      return item.id;
    }
  }
  return null;
}
