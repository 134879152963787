import React, {useState, useReducer, useEffect, useRef} from "react";
import Header from '../components/Header'
import Footer from '../components/Footer'
import Center from '../components/Center'
import Feedback from '../components/Feedback';
import IMFeedback from '../components/IMFeedback';
import LeaveMessage from '../components/LeaveMessage';
import ChooseBusiness from '../components/ChooseBusiness'
import xssOptions from '../static/xssFiltersOptions'
import Notify from 'title-notify';
import SideBar from '../components/SideBar';
import ChatFileItem from '../components/ChatFileItem';
import CluesCollect from '../components/CluesCollect';
import styles from './index.less'
import {getAnswer} from '../static/api'
import {ConfigProvider, message, Modal, Button} from 'antd';
import * as Sentry from "@sentry/react";
// import * as moment from 'moment';
import {
  toast,
  fixKeyboard,
  isWeixin,
  throttle,
  debounce,
  WSSendId,
  generateTag,
  generateWid, getUTag, uuid,
  clearOutOfDateWid,
  getGuideIndex,
  updateGuideIndex, operateMultiId, loadScript,
  checkDarkMode, setBrowserIcon
} from '../static/util/utils'
import {GUESS_LIMIT, FILE_LIMIT, IMAGE_LIMIT} from '../static/const';
import {
  setWXOpenID,
  getWXOpenID,
  setWXMark,
  getWXMark,
  hasCollectedClues,
  setSid,
  muteStatus
} from '../static/util/store';
import useInterval from '../static/util/useInterval';
import addImgTarget from '../static/util/checkImgUrl';
// import Darkmode from 'darkmode-js';
import PulseLoader from "react-spinners/PulseLoader";
import {
  getImChatConnect,
  getOpenId,
  sendMsgToIM,
  uploadImg,
  getMsgFromIM,
  postComment,
  getMsgFromIMLong,
  getInfo,
  getIMHistoryData,
  ZKIMinit,
  sendMsgToZKIM,
  uploadFileZK,
  ZKIMCancelQueue,
  ZKIMLogout,
  querySuggest,
  getAnswerById,
  ZKIMFeedbackInfo,
  ZKIMLeaveMessage,
  ZKIMSubmitFeedback,
  setReadStatus,
  getRobotGroup,
  getTempToken,
  getRecentChat,
  getHistoryList,
  getRegionsTree,
  uploadInput,
  getAutoIMConfig,
  getGuideList,
  getLatestFile,
  getUserInfo,
  robotTell,
  closeSession,
  getSessionMsgId,
  getChatsByMsgId,
  getLeaveMessageHistoryList,
  stopQuestion,
  stopAIQuestion, submitMarketing
} from '../services';

fixKeyboard();

const tostTip = toast();
const queryString = require('query-string');
const {launch_id = ''} = queryString.parse(location.search);
import * as xssFilters from 'xss';

// const randomTag = generateTag();
const randomTag = getUTag();

//机器人id
const rid = queryString.parse(location.search).rid;

//投放id
const launchId = queryString.parse(location.search).launch_id;

//隐藏转人工机器人
let robotIdArr = ['f6f49528-2e9b-4b77-8bbf-f04544f6fc77'];//a9b2af30-091f-4725-b35f-bdcd1fefdddd
const isHideRobot = robotIdArr.includes(rid);

//ISC大会临时样式
// import ISC from '../ISC/index';

//360+定制样式
import The360Plus from '../360plus';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import radarLog from "../static/util/radar";

import reducer, {changeCurrent, current} from "./reducer";
// import initQUC, {getUserData, loginHandle} from "./login";
import Login from './login';

import processControl from "./processControl";
import FunList from "./init";
import {getChattingRecords, lazyDeleteChattingRecords, saveChattingRecords} from "../static/util/chattingRecords";
import useEventSource from "../static/util/useEventSource";
import AIControl from "./AIControl";
import LoadingComponent from "../components/LoadingComponent";
import {answerController} from "./answer";
import FormModal, {getEntrance, getFormInfo, getPop} from "../components/FormModal";
import SDKModal from "../components/FormModal/SDKModal";
import H5Modal from "../components/FormModal/H5Modal";

//AI大会员rid
const AIVipRobotList = [
  '3ed1e16d-c87b-450a-b2d0-67ea40f86000',
  '3351d057-ab2f-4e03-b664-ef86a8ccb69c',
  '2608d089-0891-4bd0-8905-da60a2f2f2e9',
  'c7d541e6-e207-49bc-82df-35e1f63aa61b',
  'bbe389de-a2ab-4baf-8d5e-d9606900a87b',
  'bd5e6288-e35e-4adb-935e-3dfd3c169a1e',
  'a28a2e74-3b63-4c8e-b384-50d5c995664f',
  '4e58dc06-4345-4fbe-8e98-780048f95ac8',
  '720cab3b-f32a-4e7e-8711-2142b382a60c',
  '63d5367c-a986-4baf-b296-56f223b5e889',
  'cca77a88-a227-4051-9319-e3c0da362309',
  '4b528e17-32ff-49b1-a21f-95828a367f35',
]

// try {
//   if (AIVipRobotList && AIVipRobotList.includes(rid)) {
//     setBrowserIcon('https://p0.qhimg.com/t01297460a0bfd22e71.png');
//     // let iconList = document.querySelectorAll('link[rel*="icon"]');
//     // iconList && iconList.forEach(a => a.setAttribute('href', 'https://p0.qhimg.com/t01297460a0bfd22e71.png'))
//   }
// } catch (e) {
//
// }

// 游戏专属rid，流程增加输入游戏名称/通用的逻辑,测试环境的游戏rid：6b4ac81a-0113-493a-aa8c-2a301549242b
const isGameRobot = rid === '6b4ac81a-0113-493a-aa8c-2a301549242b' ||
  rid === 'c1ae0d32-bcc1-4855-b937-886783ce6423';

//是否开启侧边栏，目前只针对游戏开启
const showSidebar = isGameRobot;

//暂时特殊处理。隐藏header
const isHideHeaderSpecial = rid === '867b9bd4-fc62-430e-a1a1-99c1b6b1b508' ||
  rid === '29b09c69-b0aa-4510-9429-ded858e25736' || true;

//路由器不支持localstorage。暂且关闭暗黑模式
const isRouter = rid === 'f706f848-4c7d-4a8c-85a5-621b62521487';

//仅允许在推推环境运行
const justTuiTui = rid === '19511d8a-dea0-4035-ae1c-128a59de11f3';

//微信登陆后code
const WXCode = queryString.parse(location.search).code;

// const isISC = queryString.parse(location.search)._tempAuthType === 'ISC';

const is360Plus = queryString.parse(location.search)._tempAuthType === '360';

const fullScreen = queryString.parse(location.search)._tempAuthType === 'full';
const sourceOrigin = queryString.parse(location.search)._o;

const fromSDK = queryString.parse(location.search)._sdk;

//url中带有问题
const question = queryString.parse(location.search).question;
//
// getOpenId({}).then(res => {
//   console.log(res);
// });

let param = {game: '', question: ''} // 请求答案的参数
let initWelcomQuestion = (
  <div>请输入正确的【游戏名称】后咨询；<br/>如咨询：充值、账号、登陆、游戏大厅、积分商城 等问题，请输入【通用】后咨询。</div>) // 初始状态的欢迎语句， 用于资讯其他游戏时
let noAnswer = "没有答案哟~";


let wsUrl = ''
switch (process.env.NODE_ENV) {
  case "development":
    // domain = '//test.zhike.help.360.cn';
    wsUrl = 'wss://qaim.zhike.360.cn/ws';
    break;
  case "none":
    wsUrl = 'wss://qaim.zhike.360.cn/ws';
    break;
  default:
    wsUrl = 'wss://im.zhike.360.cn/ws';
}


//判断是否显示快速申请
const canCollect = () => {
  const wid = generateWid();
  return ((!hasCollectedClues(wid) && (rid === '1e8a7933-cf6a-4335-ae9f-edda95a009b3' || rid === 'cbff7b12-c7f0-4829-bb9e-170a4ab771ca' || rid === 'b4b8e017-c355-4f93-9c23-8418f0a0c252' || rid === '5fc3a3f0-7f8d-4ead-a48b-33e03c4b0c14' || rid === '29e0251c-97f9-4107-a620-6d8b02aaed64')));
}

// //判断是否显示固定入口表单
// const canShowEntranceForm = (form)=>{
//   if(form){
//     const wid = generateWid();
//     return !hasCollectedClues(`${wid}-${form.record_id}`);
//   }else{
//     return false;
//   }
// }


const isSDK = window.__IS_SDK = (fullScreen && sourceOrigin && fromSDK);
//sdk发送postmessage
const postMessage = isSDK ? (msg) => {
  if (!(fullScreen && sourceOrigin)) {
    return;
  }
  if (window.parent != window) {
    window.parent.postMessage(msg, sourceOrigin);
  }

} : null;

// darkmode.showWidget();


const HeartBeatInterval = 10000;
const HttpHeartInterval = 5000;

//问题记录
let questionMap = {};


export default function App(props) {
  let {i18n, t} = useTranslation();

  const Token = useRef(null);

  const {isMobile = false} = props
  const [dialogList, machineTransition] = useReducer(reducer, [])
  const [info, setInfo] = useState({
    kfInfo: {url: '', name: '', color: ''}, // 客服信息
    uInfo: {url: '', name: ''}       // 用户信息
  });

  //聊天记录缓存
  useEffect(() => {
    if (dialogList && window?.u_id && rid) {
      let temp = [...dialogList];
      if (dialogList.length > 50) {
        temp = temp.slice(temp.length - 50, temp.length);
      }
      saveChattingRecords(`${window?.u_id}-${rid}`, temp);
    }
  }, [dialogList]);

  const CenterRef = useRef(null);


  //是否显示固定入口表单
  const [showEntranceForm, setShowEntranceForm] = useState(false);
  const [__, set__] = useState(new Date());
  useEffect(() => {
    if(info && __){
      let form = getEntrance(info?.kfInfo?.formInfo);
      if (form) {
        const wid = generateWid();
        console.log('setShowEntranceForm',__,!hasCollectedClues(`${window?.u_id}-${form.record_id}`));
        setShowEntranceForm(!hasCollectedClues(`${window?.u_id}-${form.record_id}`));
      } else {
        setShowEntranceForm(false);
      }
    }
  }, [info, __]);

  const [isInit, setIsInit] = useState(false);

  const [showMoreFun, setShowMoreFun] = useState(false);

  const [moreFunList, setMoreFunList] = useState([]);

  //im的更多功能。仅在IM连接后展示
  const [imMoreFunList, setImMoreFunList] = useState([]);

  const [showFeedback, setShowFeedback] = useState(false);
  const [showImLeftMessage, setShowImLeftMessage] = useState(false);
  const [leaveMessageToken, setLeaveMessageToken] = useState('');
  const [leaveContent, setLeaveContent] = useState("");
  const [showImFeedback, setShowImFeedback] = useState(false);
  //主动触发满意度
  const [feedbackInitiative, setFeedbackInitiative] = useState(true);
  const [showImChooseBusiness, setShowImChooseBusiness] = useState(false);
  const [showBusinessType, setShowBusinessType] = useState(null);
  //IM连接状态
  const [isIMConnect, setIsIMConnect] = useState(false);
  const isIMConnectRef = useRef(isIMConnect);
  //记录连接中弱文本索引
  const isIMConnecting = useRef(-1);
  //窗口是否为可见
  const [hidden, setHidden] = useState(true);
  const focusHandle = useRef();
  //未读计数器
  const [unReadMap, setUnReadMap] = useState(0);

  //IM黑名单状态
  const [isBan, setIsBan] = useState(false);

  //新消息提醒
  const [notify, setNotify] = useState(null);
  //是否自动重连
  const [isAutoConnect, setIsAutoConnect] = useState(false);

  //显示客服正在输入中
  const [isInputing, setIsInputing] = useState(false);
  const [inputingTimer, setInputingTimer] = useState(null);
  //接待组列表
  const [receptionGroup, setReceptionGroup] = useState(null);
  const [receptionMember, setReceptionMember] = useState(null);
  //待重发消息队列
  const [sendMsgQueue, setSendMsgQueue] = useState([]);

  //心跳队列
  const sendHeartQueue = useRef([]);
  const sendHeartTimeoutQueue = useRef([]);
  const [heartErrorCount, setHeartErrorCount] = useState(0);
  useEffect(() => {
    if (heartErrorCount >= 2 && isIMConnect) {
      updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
      machineTransition({type: 'weakText', param: `网络连接异常，请重新连接`});//(res.data.status_desc || '客服妹子和您建立了会话')
      closeIMConnect();
      // console.log(heartErrorCount);
    }
  }, [heartErrorCount, isIMConnect]);

  //自动转人工相关属性
  //自动转人工配置信息
  const [autoIMConfigData, setAutoIMConfigData] = useState(null);
  //直接回复次数
  const [directlyResponseNum, setDirectlyResponseNum] = useState(0);
  //直接回复连续触发次数
  const [directlyResponseContinuousNum, setDirectlyResponseContinuousNum] = useState(0);
  //直接回复触发
  useEffect(() => {
    triggerResponseNum(directlyResponseNum, 'reply_model');
  }, [directlyResponseNum]);
  //未知回复次数
  const [unknownResponseNum, setUnknownResponseNum] = useState(0);
  //未知回复连续触发次数
  const [unknownResponseContinuousNum, setUnknownResponseContinuousNum] = useState(0);
  //未知回复计数触发
  useEffect(() => {
    triggerResponseNum(unknownResponseNum, 'unknown_model');
  }, [unknownResponseNum]);
  //未知回复连续计数触发
  useEffect(() => {
    triggerResponseNum(unknownResponseContinuousNum, 'unknown_model', true);
  }, [unknownResponseContinuousNum]);
  //猜你想问次数
  const [guessResponseNum, setGuessResponseNum] = useState(0);
  //猜你想问连续触发次数
  const [guessResponseContinuousNum, setGuessResponseContinuousNum] = useState(0);
  //猜你想问计数触发
  useEffect(() => {
    triggerResponseNum(guessResponseNum, 'guess_model');
  }, [guessResponseNum]);
  //猜你想问连续计数触发
  useEffect(() => {
    triggerResponseNum(guessResponseContinuousNum, 'guess_model', true);
  }, [guessResponseContinuousNum]);
  //重复问题触发次数
  const [questionRepeatNum, setQuestionRepeatNum] = useState(0);
  //重复问题连续触发次数
  const [questionRepeatContinuousNum, setQuestionRepeatContinuousNum] = useState(0);
  //重复问题计数触发
  useEffect(() => {
    triggerResponseNum(questionRepeatNum, 'repeat_model');
  }, [questionRepeatNum]);
  //重复问题连续计数触发
  useEffect(() => {
    triggerResponseNum(questionRepeatContinuousNum, 'repeat_model', true);
  }, [questionRepeatContinuousNum]);
  //不满意次数
  const [dissAnswerNum, setDissAnswerNum] = useState(0);
  //不满意连续触发次数
  const [dissAnswerContinuousNum, setDissAnswerContinuousNum] = useState(0);
  //不满意计数触发
  useEffect(() => {
    triggerResponseNum(dissAnswerNum, 'dissatisfied_model');
  }, [dissAnswerNum]);
  //不满意连续计数触发
  useEffect(() => {
    triggerResponseNum(dissAnswerContinuousNum, 'dissatisfied_model', true);
  }, [dissAnswerContinuousNum]);
  //当前自动转人工回复的会话id
  const [currentAutoIMItemID, setCurrentAutoIMItemID] = useState(0);

  //是否展示新消息提示框
  const [showNewInformationTips, setShowNewInformationTips] = useState(false);
  const [newInformationNumber, setNewInformationNumber] = useState(0);

  //登陆控制器
  const [loginControl, setLoginControl] = useState(null);

  //滚动通知内容
  const [noticeContent, setNoticeContent] = useState(null);

  //是否开启用户主动关闭会话
  const [userCloseSession, setUserCloseSession] = useState(false);


  //转人工模式
  const [connectIMType, setConnectIMType] = useState();


  //线索收集表单
  const [formInfo, setFormInfo] = useState(null);
  const [formVisible, setFormVisible] = useState(false);

  const {
    createEventSource,
    eventSourceData,
    eventSourceState,
    closeEventSource
  } = useEventSource();

  useEffect(() => {
    // createEventSource({
    //   // http://10.16.49.85:10081/api/v1/ai_chat/ai_doc/question
    //   url: `/api/v1/submitAIQus`, options: {
    //     method: 'POST',
    //     timeout: 60000,
    //     // mode: 'no-cors',
    //     headers: {
    //       'Content-Type': 'application/json;charset=UTF-8',
    //       // 'Content-Type': 'text/event-stream',
    //       'accept': '*/*',
    //     },
    //     body: JSON.stringify({
    //       "rid": "d22f4fc0-22e4-45e1-b8f3-2cacb45c8942",
    //       "tag": "kO7gnaO4JxMKTbaBeuGbVKIfLWl5UT5C",
    //       "question": "工卡丢失如何补办?",
    //       "wid": "W7pkk7VxVIkD0dJfnnqO5Y9qgFDgr66K",
    //       "launch_id": "48e2ae72-d478-409e-8ed3-a10c12455f34"
    //     })
    //   },
    //
    // });
  }, []);

  useEffect(() => {
    if (eventSourceData?.questionId) {

      machineTransition({
        type: 'update', msgId: eventSourceData?.id, callback: (item) => {
          if (item && item.content) {
          } else {
            item.content = eventSourceData?.data?.data;
          }
          item.component = null;
          return item;
        }
      });

      machineTransition({
        type: 'update', id: eventSourceData?.questionId, callback: (item) => {
          // item.loading = 'error';
          item.duringQuestion = false;
          return item
        }
      });
      // machineTransition({type: 'delete', param: eventSourceData?.id});
      return;
    }

    if (eventSourceData?.id) {
      // console.log(eventSourceData?.id, eventSourceData?.data);
      machineTransition({
        type: 'update', msgId: eventSourceData?.id, callback: (item) => {
          delete item.loading;
          delete item.component;
          item.duringQuestion = true;
          eventSourceData?.loading && (item.loading = eventSourceData.loading);
          item.hideCollapse = true;
          item.typedInput = true;
          item.answerType = eventSourceData?.data?.answer_type;
          console.log(item.answerType);
          eventSourceData?.data?.id && (item.id = eventSourceData?.data?.id);
          eventSourceData?.data?.is_rag_stop && (item.stopQuestion = eventSourceData?.data?.is_rag_stop);
          // console.log('content',item.content);
          if (eventSourceData?.data?.answer_type === undefined || eventSourceData?.data?.answer_type < 6) {
            item.content = `${item.content ? item.content : ''}${eventSourceData?.data?.data}`
          } else if (eventSourceData?.data?.answer_type && eventSourceData?.data?.answer_type >= 6) {
            if (!item.content) {
              item.content = `当前咨询较多，请稍后再试`;
            }
            try {
              // Sentry.captureMessage(`${JSON.stringify(eventSourceData)}`);
            } catch (e) {

            }
          }
          // (eventSourceData?.data?.answer_type !== undefined && eventSourceData?.data?.answer_type < 6 ) && (item.content = `${item.content ? item.content : ''}${eventSourceData?.data?.data}`);
          return item
        }
      });

      if (eventSourceData?.data?.resp?.data?.dialogue_id) {
        machineTransition({type: 'delete', param: eventSourceData?.id});
        //如果有多轮对话id则保存
        operateMultiId({
          id: eventSourceData?.data?.resp?.data?.dialogue_id,
          expand: {is_big_vip: eventSourceData?.data?.resp?.data?.is_big_vip}
        });

        send.apply(null, [eventSourceData?.data?.data, undefined, {...eventSourceData?.data}]);
        return;
      }
      if (eventSourceData?.data?.answer_type !== undefined && eventSourceData?.data?.answer_type < 6) {
        machineTransition({type: 'delete', param: eventSourceData?.id});
        let countItem = questionMap[`${eventSourceData?.stringToSend}`] || {};
        answerController({
          resp: eventSourceData?.data?.resp,
          info,
          countItem,
          machineTransition, questionMap, setCurrentAutoIMItemID, countResponse, countRepeatResponse
        })
      }

    }


  }, [eventSourceData]);

  const stopQuestion = () => {
    if (eventSourceState === 1) {
      let rag_id = eventSourceData?.data?.rag_id;
      console.log(rag_id);
      rag_id && stopAIQuestion({msg_id: rag_id, rid});
      closeEventSource();
    }
  }

  //判断是否在活
  // const [appStateVisible, setAppStateVisible] = useState();
  //
  // const _handleAppStateChange = (nextAppState) => {
  //   console.log('状态：++++++++++++++', nextAppState);
  //   console.log('当前状态：+++++++++++++', document.visibilityState);
  // };

  useEffect(() => {
    if (!isMobile) {
      return
    }
    ;
    // console.log('情况正在起变化', document.visibilityState);
    if (document.visibilityState === 'hidden') {
      ws && ws.current && ws.current.close(1000);
    }
  }, [document.visibilityState])

  // useEffect(()=>{
  //   //AI大会员 favicon更换
  //   // debugger;
  //   let iconList = document.querySelectorAll('link[rel*="icon"]');
  //   iconList && iconList.forEach(a=>a.setAttribute('href','https://p0.qhimg.com/t01297460a0bfd22e71.png'))
  //   // let iconLink = document.querySelector('link[rel*="icon"]');
  //   // iconLink.setAttribute('href','https://p0.qhimg.com/t01297460a0bfd22e71.png') ;
  // },[]);

  //计数触发方法
  const triggerResponseNum = (num, modelName, continuous = false) => {

    let connectType = 3;
    switch (modelName) {
      case 'repeat_model':
        connectType = 4;
    }

    if (autoIMConfigData && info.kfInfo && info.kfInfo.customer_mode !== 1 && !isBan && num && num > 0) {
      const model = autoIMConfigData[modelName];
      if (model && model.switch === 1) {
        if (model && model.transfer_type !== undefined && model.times && num >= model.times) {
          switch (model.transfer_type) {
            case 1:
              machineTransition({
                type: 'showConnectIM', prompt: model.prompt, msgId: currentAutoIMItemID, callback: (item) => {
                  item.showIMBtn = true;
                  return item
                }
              });
              return;
            case 2:
              connectIM(false, model.prompt, null, connectType);
              return;
          }
        }
        if (model.display_button && currentAutoIMItemID && !continuous) {
          machineTransition({
            type: 'showConnectIM', prompt: model.prompt, msgId: currentAutoIMItemID, callback: (item) => {
              item.showIMBtn = true;
              return item
            }
          });
        }
        const {direct_to_human} = model;
        if (direct_to_human && direct_to_human.switch === 1) {
          const config = (continuous ? direct_to_human.continuous : direct_to_human.cumulation);
          if (config && config.switch === 1 && config.times) {
            if (num >= config.times) {
              connectIM(false, model.prompt, null, connectType);
            }
          }
        }
      }
    }
  }
  //计数方法
  const countResponse = (type = "", clearAll = false) => {
    const map = {
      DirectlyResponse: setDirectlyResponseNum,
      DirectlyResponseContinuous: setDirectlyResponseContinuousNum,
      UnknownResponse: setUnknownResponseNum,
      UnknownResponseContinuous: setUnknownResponseContinuousNum,
      GuessResponse: setGuessResponseNum,
      GuessResponseContinuous: setGuessResponseContinuousNum,
      // QuestionRepeat: setQuestionRepeatNum,
      // QuestionRepeatContinuous: setQuestionRepeatContinuousNum
    }

    if (clearAll) {
      countDissResponse(false, true);
      setQuestionRepeatNum(0);
      setQuestionRepeatContinuousNum(0);
      questionMap = {};
    }

    for (let key in map) {
      if (clearAll) {
        map[key](0);
        continue;
      }

      if (key.indexOf(type) >= 0) {
        map[key](prev => ++prev);
      } else {
        if (key.indexOf('Continuous') > 0) {
          map[key](0);
        }
      }
    }
    // setGuessResponseNum(prev => ++prev);
    // setGuessResponseContinuousNum(prev => ++prev);
    // setUnknownResponseContinuousNum(0);
    // setDissAnswerContinuousNum(0);
    // setDirectlyResponseContinuousNum(0);
  }

  //点赞点踩信息单独计数
  const countDissResponse = (count, clearAll = false) => {
    if (clearAll) {
      setDissAnswerNum(0);
      setDissAnswerContinuousNum(0);
      return;
    }
    if (count) {
      setDissAnswerNum(prev => ++prev);
      setDissAnswerContinuousNum(prev => ++prev);
    }
  }

  //重复问题单独计数
  const countRepeatResponse = (countItem) => {
    //重复问题计数 (重复问题单独计数，answer_type不能中断重复计数的连续计数)
    // countResponse('')
    if (countItem && countItem?.count >= 2) {
      setQuestionRepeatNum(countItem?.count);
    }
    if (countItem && countItem?.continuous >= 2) {
      setQuestionRepeatContinuousNum(countItem?.continuous);
    }
  }

  //清空心跳检测队列
  const clearHeartQueue = () => {
    setHeartErrorCount(0);
    sendHeartQueue.current = [];
    if (sendHeartTimeoutQueue && sendHeartTimeoutQueue.current?.length >= 0) {
      for (let i = 0; i < sendHeartTimeoutQueue.current.length; i++) {
        let id = sendHeartTimeoutQueue.current[i];
        clearTimeout(id);
      }
    }
    sendHeartTimeoutQueue.current = [];
  }

  //发送全部待发送消息
  const sendAllMsgInQueue = async () => {
    let newArr = [];
    let hasError = false;
    for (let index = 0; index < sendMsgQueue.length; index++) {
      let item = sendMsgQueue[index];

      if (hasError) {
        machineTransition({
          type: 'update', msgId: item.msgId, callback: (item) => {
            item.loading = 'error';
            return item
          }
        });
      }
      try {
        let res = await sendMsgToZKIM({serviceToken: Token?.current || info.kfInfo.wsToken, message: item.param});
        // console.log(res);
        if (res && res.code === 200 && res.data && res.data.sendResult) {
          machineTransition({
            type: 'update', msgId: item.msgId, callback: (item) => {
              delete item.loading;
              item.msgId = res.data.msgId;
              if (item.msgType === 3 && item.fileUrl) {
                item.file = {...item.file, fileUrl: item.fileUrl};
              }
              return item
            }
          });
        } else {
          throw new Error();
        }
      } catch (e) {

        item.retryCount = (item.retryCount || 0) + 1;
        if (item.retryCount >= 5) {
          machineTransition({
            type: 'update', msgId: item.msgId, callback: (item) => {
              item.loading = 'error';
              return item
            }
          });
          updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
          machineTransition({type: 'weakText', param: `网络连接异常，请重新连接`});//(res.data.status_desc || '客服妹子和您建立了会话')
          closeIMConnect();
          hasError = true;
          break;
        } else {
          // newArr.push(item);
          newArr = sendMsgQueue.slice(index)
          setTimeout(() => {
            // machineTransition({type: 'weakText', param: `retry3`});
            wsRetry();
          });
          return;
        }

      }
    }
    setSendMsgQueue(newArr);

  }

  const closeAllMsgInQueue = () => {
    // console.log('清空队列');
    for (let index = 0; index < sendMsgQueue.length; index++) {
      let item = sendMsgQueue[index];
      try {
        machineTransition({
          type: 'update', msgId: item.msgId, callback: (item) => {
            item.loading = 'error';
            return item
          }
        });
      } catch (e) {

        machineTransition({
          type: 'update', msgId: item.msgId, callback: (item) => {
            item.loading = 'error';
            return item
          }
        });
      }
    }
    setSendMsgQueue([]);
  }


  const [firstShow, setFirstShow] = useState(false);
  const firstShowHandle = useRef();

  let firstShowObserver;

  useEffect(() => {
    const notify = new Notify({
      message: '您有未读消息', // page title.
      effect: 'scroll', // flash | scroll, Flashing or scrolling
      interval: 1000,
      audio: {
        file: 'https://pub-shbt.s3.360.cn/zhike-admin/notice.mp3',
        // file: 'https://pub-shbt.s3.360.cn/zhike-im-file/notice.mp3',
      },
      // notification: {
      //   icon: 'https://p2.ssl.qhimg.com/t011c13f9842f5044b3.png',
      // },
    });
    notify.faviconClear();
    // notify.loopPlay();
    setNotify(notify);
    setHidden(false);

    //清空过期localstorage
    clearOutOfDateWid();
  }, []);

  useEffect(() => {
    let mute = muteStatus();
    if (mute) {
      return;
    }
    if (notify) {
      notify.faviconClear();
      if (unReadMap > 0) {
        notify.setTitle(`您有【${unReadMap}】条未读消息`);
        notify.setTitle(true);
        // systemNotice('您有新的消息，请尽快查看处理。');
        notify.player();
        // notify.notify({
        //   title: '您有新的消息，请尽快查看处理。',
        //   icon: 'https://p2.ssl.qhimg.com/t011c13f9842f5044b3.png',
        // });
      } else {
        notify.setTitle();
        document.title = '智能客服';
      }
    }
    notify?.faviconClear();
    document.getElementById('newfavicon') &&
    document.getElementById('newfavicon').remove();

    // console.log(count);
  }, [unReadMap, notify]);

  useEffect(() => {
    if (isAutoConnect) {
      radarLog({c: 'xt_transfer'});
      connectIM(true, null, null, 3);
    }
  }, [isAutoConnect]);

  useEffect(() => {

    setHidden(false);
    // window.onfocus = () => {
    //   focusHandle.current();
    // }
    //
    // window.onblur = () => {
    //   setHidden(true);
    // }

    document && document.addEventListener("visibilitychange", (evt) => {
      // evt = evt || window.event;
      setVisibilityState();
    });

  }, []);

  //监听postmessage
  useEffect(() => {
    if (sourceOrigin) {
      // console.log(sourceOrigin);
      window.addEventListener('message', (e) => {
        const {data = {}} = e;
        switch (data.type) {
          case 'FIRST-SHOW':
            try {
              firstShowHandle.current();
              setFirstShow(true);
              CenterRef.current && CenterRef.current.scrollToBottom && CenterRef.current.scrollToBottom();
              firstShowObserver && firstShowObserver();
              // robotTell({rid});
            } catch (e) {
            }
            break;
          case 'SHOW-FRAME':
            try {
              robotTell({rid});
            } catch (e) {
            }
            break;
          case 'GET-LOCAL-ID':
            postMessage({
              type: 'GET-LOCAL-ID',
              wid: generateWid(),
              rid: rid,
              uid: window.u_id
            });
            break;
        }
      }, false);
    }
  }, []);
  // useEffect(() => {
  //   console.log(fullScreen);
  //   console.log(sourceOrigin);
  //   console.log(queryString.parse(location.search)._tempAuthType);
  // }, []);

  const clearInputingFun = () => {
    clearTimeout(inputingTimer);
    setIsInputing(false);
  }

  const setInputingFun = () => {
    setIsInputing(true);
    let timer = setTimeout(() => {
      clearInputingFun();
    }, 25000);
    setInputingTimer(timer);
  }

  const setVisibilityState = debounce(() => {
    // alert(document.visibilityState);
    if (document.visibilityState === 'visible') {
      focusHandle.current();
      setUnReadMap(0);
    } else {
      setHidden(true);
    }
  }, 500);

  useEffect(() => {
    focusHandle.current = () => {
      setHidden(false);
      if (isIMConnect) {
        setReadStatus({service_token: Token?.current || info.kfInfo.wsToken || info.kfInfo.tempToken});
      }
    }
    firstShowHandle.current = () => {
      // loginControl && loginControl.login();
      getAnswer({
        question: info.kfInfo?.greet,
        send_message_type: 'welcome'
      })
    }
  }, [info, isIMConnect]);

  useEffect(() => {
    updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
    isIMConnectRef.current = isIMConnect;
  }, [isIMConnect])
  const updateIMConnectingWeakText = useRef();
  useEffect(() => {
    updateIMConnectingWeakText.current = () => {
      if (isIMConnecting?.current != -1) {
        machineTransition({
          type: 'update', msgId: isIMConnecting?.current, callback: (item) => {
            item.content = null;
            return item;
          }
        });
        isIMConnecting.current = -1;
      }
    }
  })

  const closeReceptionModal = () => {
    setShowImChooseBusiness(false);
    if (isIMConnecting?.current != -1) {
      machineTransition({
        type: 'update', msgId: isIMConnecting?.current, callback: (item) => {
          item.content = null;
          return item;
        }
      });
      isIMConnecting.current = -1;
    }
  };
  //im服务评价只允许提交一次
  const [canFeedback, setCanFeedback] = useState(true);

  const [longPollingState, setLongPollingState] = useState(true);


  const [addDialogActive, setAddDialogActive] = useState('');

  const [haveMoreData, setHaveMoreData] = useState(true);

  const [zkHaveMoreData, setZkHaveMoreData] = useState(true);

  const [wsHeart, setWsHeart] = useState(-1);
  const [httpHeart, setHttpHeart] = useState(-1);

  const [retrying, setRetrying] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const [leaveMessageHeart, setLeaveMessageHeart] = useState(-1);

  const [needRetry, setNeedRetry] = useState(true);


  const IM_WAITING_INTERVAL = 1000;
  const [imWaiting, setImWaiting] = useState(null);
  const [imWaitingNetWorkError, setImWaitingNetWorkError] = useState(false);
  const imWaitingErrorTimeout = useRef(null);
  const [waitingTime, setWaitingTime] = useState(0);
  // const [cancelWaitType, setCancelWaitType] = useState(0);
  const [feedbackConfig, setFeedbackConfig] = useState(null);
  const [waitQueueInfo, setWaitQueueInfo] = useState(null);


  //线索引导语
  const [currentGuideIndex, setCurrentGuideIndex] = useState(null);
  const [guideList, setGuideList] = useState([]);
  const [guideTimeoutId, setGuideTimeoutId] = useState(null);
  const [guideSendModel, setGuideSendModel] = useState(1);

  //转人工按钮
  const [hideButton, setHideButton] = useState(false);


  const [customerMode2Once, setCustomerMode2Once] = useState(false);
  useEffect(() => {
    if (customerMode2Once) {
      return;
    }
    //仅人工模式、初始化成功后自动转人工
    if (isInit && !imWaiting) {
      if (info.kfInfo && info.kfInfo.customer_mode === 2) {
        if (isSDK) {
          if (!firstShow) {
            return;
          }
        }
        radarLog({c: 'xt_transfer'});
        connectIM(false, null, null, 3);
        setCustomerMode2Once(true);
      }
    }
  }, [isInit, info, firstShow, customerMode2Once]);


  useInterval(() => {
    setWaitingTime(pre => {
      let result = pre;
      result += 1;
      return result
    })
  }, imWaiting);

  useEffect(() => {
    if (currentGuideIndex !== null && guideList && guideList.length > 0 && currentGuideIndex < guideList.length) {
      let item = guideList[currentGuideIndex];
      let temp = setTimeout(() => {
        // console.log('guide', item.content);
        machineTransition({
          type: 'addGuide',
          param: {
            content: item.content,
          },
        });
        getAnswer({
          question: item.content,
          send_message_type: 'welcome'
        });
        let index = currentGuideIndex + 1;
        if (index < guideList.length) {
          setCurrentGuideIndex(index);
          updateGuideIndex(index, guideSendModel);
        } else {
          updateGuideIndex(null, guideSendModel);
        }
      }, item.delay * 1000);
      setGuideTimeoutId(temp);
    }
  }, [currentGuideIndex, guideList]);


  useEffect(() => {
    if (justTuiTui && !window._encrypt_info) {
      return;
    }
    // 初始化机器人
    changeInit()
  }, []);

  //排队轮询
  useInterval(() => {
    if (imWaitingNetWorkError) {
      return;
    }
    // if (cancelWaitType !== 1) {
    if (Array.isArray(receptionMember) && receptionMember.length > 0) {
      wsInit({
        receptions: receptionMember
      });
    } else {
      wsInit();
    }
    // }
  }, imWaiting * 5);

  //排队错误处理
  const waitingErrorHandle = () => {
    imWaitingErrorTimeout?.current && (clearTimeout(imWaitingErrorTimeout.current), imWaitingErrorTimeout.current = null);
    tostTip('您当前网络异常');
    updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
    setWaitQueueInfo(null);
    setImWaitingNetWorkError(true);
  }

  useEffect(() => {
    if (isIMConnect === false) {
      let temp = moreFunList.filter((item) => {
        return imMoreFunList.find(moreItem => {
          return moreItem === item;
        }) ? false : true;
      });
      setMoreFunList(temp);
      setShowFeedback(false);
    }
  }, [isIMConnect]);


  useEffect(() => {
    // if (isMobile && !isRouter && checkDarkMode && checkDarkMode()) {
    //   new Darkmode({
    //     saveInCookies: false,
    //   });
    // }
    return () => {
      setLongPollingState(false);
    }
  }, []);

  useEffect(() => {
    if (!canFeedback && showFeedback) {
      tostTip(t('already-evaluated'));
      setShowFeedback(false);
    }
  }, [canFeedback, showFeedback]);

  //发送心跳并判断回执
  useInterval(() => {
    let sendId = WSSendId();
    let message = '{"seq":"' + sendId + '","cmd":"heartbeat","data":{"serviceToken":"' + (Token?.current || info.kfInfo.wsToken) + '"}}';
    ws.current && ws.current.send(message);
    let queue = (sendHeartQueue && sendHeartQueue.current) || [];
    queue.push(sendId);
    sendHeartQueue.current = queue;

    let sendQueue = (sendHeartTimeoutQueue && sendHeartTimeoutQueue.current) || [];
    let timeoutId = setTimeout(() => {
      let queue = sendHeartQueue.current || [];
      queue = [...queue];
      let index = queue.findIndex(item => item === sendId);
      if (index >= 0) {//心跳检测异常
        setHeartErrorCount(prev => ++prev);
      }

      if (sendQueue && sendQueue.length) {
        sendQueue = [...sendQueue];
        sendQueue.splice(sendQueue.findIndex(item => item === timeoutId));
        sendHeartTimeoutQueue.current = sendQueue;
      }
      // console.log(queue, sendQueue);
    }, wsHeart - 7000);
    sendQueue.push(timeoutId);
    sendHeartTimeoutQueue.current = sendQueue;

  }, wsHeart);
  const httpHeartHandle = async () => {
    try {
      if (!isIMConnectRef?.current) {
        return;
      }
      let res = await getSessionMsgId({serviceToken: Token?.current || info.kfInfo.wsToken});
      if (res && res.data && res.data.length) {
        const arr = [];
        res.data.map(async item => {
          let index = dialogList.findIndex(i => i.msgId === item);
          if (index < 0) {
            arr.push(item);
          }
        });
        if (arr && arr.length) {
          console.log('缺少数据的数组', arr);
          let result = await getChatsByMsgId({
            serviceToken: Token?.current || info.kfInfo.wsToken,
            msgIds: arr.join(',')
          })
          result && result.data && messageHandle && messageHandle.current(result.data);
          console.log(result);
        }

      }
      // console.log(res, dialogList);
    } catch (e) {

    }
  }
  useInterval(async () => {
    httpHeartHandle();
  }, httpHeart);


  //发送留言轮询
  useInterval(async () => {
    if (!isIMConnect) {
      updateNewMessage();
    }
  }, leaveMessageHeart);

  // useEffect(() => {
  // if (imWaiting != null && imWaiting <= 0) {
  //   ZKIMCancelQueue({serviceToken: info.kfInfo.wsToken, queue_time: waitingTime, type: cancelWaitType});
  //   setWaitingTime(0);
  // }
  // } else if (imWaiting && imWaiting > 0 && cancelWaitType === 1) {
  //   // setImWaiting(null);
  // }
  // }, [imWaiting]);

  window.onbeforeunload = function (e) {
    setWaitingTime(0);
    imWaiting ? ZKIMCancelQueue({
      serviceToken: Token?.current || info.kfInfo.wsToken,
      queue_time: waitingTime,
      type: 1
    }) : void (0);
    // isIMConnect ? ZKIMLogout({serviceToken: info.kfInfo.wsToken}) : void (0);
  };


  useEffect(() => {
    if (!isMobile) {
      return
    }
    if (retryCount > 0 && document.visibilityState === 'visible') {
      wsInit({
        receptions: receptionMember,
        type: undefined,
        retry: true,
        fastRetry: true,
      });

      async function getRecent() {
        // let recentRes = await getRecentChat({
        //   appId: info?.kfInfo?.channel_id,
        //   serviceToken: info?.kfInfo?.tempToken,
        //   robot_id: info?.kfInfo?.record_id
        // });
        // let res = await getHistoryList({
        //   serviceToken: info?.kfInfo?.tempToken,
        //   appId: info?.kfInfo?.channel_id,
        // });
        //
        // if (res && res.data.list && Array.isArray(res.data.list)) {
        //   let list = res.data.list;
        //   // let firstIndex = list.findIndex(item=>{});
        //   machineTransition({
        //     type: 'coverHistory',
        //     param: {
        //       list,
        //       init: false,
        //     },
        //     robotInfo: info
        //   })
        // }


        // debugger;
        if (dialogList && dialogList.length) {
          let lastIndex = dialogList.findLastIndex(item => item._id && item._id !== '' && item.chatType);
          if (lastIndex === -1) {
            return;
          }
          let lastItem = dialogList[lastIndex];
          let msgId = lastItem._id || '';
          let robotId = lastItem.robotId || '';
          try {
            let res = await getHistoryList({
              serviceToken: Token?.current || info?.kfInfo?.tempToken,
              last_msg_id: msgId,
              appId: info?.kfInfo?.channel_id,
              robot_id: robotId,
              is_latest: 1,
            });
            if (res && res.data.list && Array.isArray(res.data.list)) {
              let index = res.data.list.findIndex(item => item.msgId === msgId);
              // console.log(111111111, index);
              if (index && index >= 0) {
                machineTransition({
                  type: 'addNewMessage', param: {
                    list: res.data.list.filter((i, ind) => ind > index)
                  }
                })
              }

            }
          } catch (e) {

          }
        }
        return;
      }

      // getRecent();
      // getHistoryHandle('down');

    }
  }, [retryCount, document.visibilityState]);

  // useEffect(() => {
  //   if (wsInitData) {
  //     wsInit();
  //   }
  //
  // }, [wsInitData]);

  //知客im websocket
  const ws = useRef(null);
  const wsOpenHandle = useRef();
  const wsCloseHandle = useRef();
  const wsErrorHandle = useRef();
  const wsMessageHandle = useRef();
  const imgUploadHandler = useRef();
  const fileUploadHandler = useRef();
  const zkIMSendHandler = useRef();
  const messageHandle = useRef();//消息处理包括http

  //websocket事件绑定
  useEffect(() => {
    messageHandle.current = async function (msgs) {
      try {
        if (msgs) {
          let arr = Array.isArray(msgs) ? msgs : [msgs?.response.data];
          // if
          arr.map(result => {
            clearInputingFun();
            hidden && setUnReadMap(pre => {
              let result = pre;
              result = pre + 1;
              return result;
            });
            hidden ? void (0) : setReadStatus({service_token: Token?.current || info.kfInfo.wsToken || info.kfInfo.tempToken});
            if (result) {

              if (result.type === 'img' || result.msg_type === 2 || result.msgType === 2) {
                let msg;
                try {
                  msg = JSON.parse(result.msg);
                } catch (e) {

                }
                let fileUrl = msg && msg.fileUrl;
                if (Array.isArray(result.message)) {
                  let url = result.message.find(item => item.Key === 'fileUrl');
                  url && url.Value && (fileUrl = url.Value);
                }
                if (fileUrl) {
                  machineTransition({
                    type: 'dealResponse', param: {
                      hideCollapse: true,
                      _id: result?._id,
                      message: result.message,
                      msgTime: result.msgTime || result.msg_time,
                      content: `<img src=${fileUrl} />`,
                      msgType: 2,
                      imageUrl: fileUrl,
                      imInfo: {nickName: info.kfInfo.imNickName},
                      msgId: result.msgId || result.msg_id,
                      speakerType: result.speakerType || result.speaker_type,
                    }
                  });
                }
              } else if (result.type === 'file' || result.msg_type === 3 || result.msgType === 3) {
                let msg;
                try {
                  msg = JSON.parse(result.msg);
                } catch (e) {

                }
                if (Array.isArray(result.message)) {
                  let obj = {};
                  result.message.map(item => {
                    obj[item['Key']] = item['Value'];
                  });
                  obj && (msg = obj);
                }
                if (msg.fileUrl) {
                  machineTransition({
                    type: 'dealResponse', param: {
                      _id: result?._id,
                      msgType: 3,
                      message: result.message,
                      msgTime: result.msgTime || result.msg_time,
                      imInfo: {nickName: info.kfInfo.imNickName},
                      component: ChatFileItem,
                      file: msg,
                      uniqueName: msg?.uniqueName,
                      msgId: result.msgId || result.msg_id,
                      speakerType: result.speakerType || result.speaker_type,
                    }
                  });

                }
              } else {
                machineTransition({
                  type: 'dealResponse', param: {
                    _id: result?._id,
                    msgTime: result.msgTime || result.msg_time,
                    content: result.msg || result.message,
                    imInfo: {nickName: info.kfInfo.imNickName},
                    aTarget: true,
                    msgId: result.msgId || result.msg_id,
                    speakerType: result.speakerType || result.speaker_type,
                  }
                });
              }


            }
          });
        }
      } catch (e) {

      }
    }
    wsOpenHandle.current = () => {
      console.log('open');
      const {wsToken, channel_id, imNickName} = info.kfInfo;
      const {imName} = info.uInfo;
      let message = '{"seq":"' + WSSendId() + '","cmd":"login","data":{"serviceToken":"' + (Token?.current || wsToken) + '","userId":"' + imName + '","appId":"' + channel_id + '"}}';
      ws.current && ws.current.send(message);

    };

    wsCloseHandle.current = (event) => {
      console.log('ws closed', event, event.code);

      //wsinit 前关闭之前ws
      if (event?.code === 3001) {
        return;
      }

      // if (event?.code === 1000) {
      // debugger;
      // return;
      // }

      setWsHeart(-1);
      // setHttpHeart(-1);
      // machineTransition({type: 'weakText', param: `retry4`});
      wsRetry();

      setTimeout(() => {
        setRetryCount(0);
        setRetrying(false);
        closeIMConnect();
        machineTransition({type: 'weakText', param: `检测到会话接待时长异常，会话已断开`});
      }, 120 * 60 * 1000);

      // let res = await getHistoryList({
      //   serviceToken: info?.kfInfo?.tempToken,
      //   last_msg_id: msgId,
      //   appId: info?.kfInfo?.channel_id,
      //   robot_id: robotId,
      //   is_latest: 1,
      // });

      // if (needRetry) {
      //   setWsHeart(-1);
      //   wsRetry();
      // } else {
      //   //断开操作
      // const {imNickName} = info.kfInfo;
      // isIMConnectRef.current === true && machineTransition({type: 'weakText', param: `会话连接出错，请重新尝试`});//(res.data.status_desc || '客服妹子和您建立了会话')
      // closeIMConnect();
      // }

    };

    wsErrorHandle.current = () => {
      wsRetry();
      // console.log('error');
      // closeIMConnect();
      //
      // machineTransition({type: 'weakText', param: `网络连接异常，请重新连接`});
    };

    wsMessageHandle.current = (e) => {
      // console.log('message', e);
      if (e.data) {
        let result;
        try {
          result = JSON.parse(e.data);
        } catch (e) {

        }

        switch (result.cmd) {
          case 'heartbeat':
            if (result.response && result.response.code === 200 && result.seq) {
              console.log('heartbeat', result.seq);
              generateWid();
              clearHeartQueue();
              try {
                if (sendHeartQueue && sendHeartQueue.current && sendHeartQueue.current.length) {
                  let queue = sendHeartQueue.current || [];
                  queue = [...queue];
                  queue.splice(queue.findIndex(item => item === result.seq), 1);
                  sendHeartQueue.current = queue;
                }
              } catch (e) {

              }
            }
            break;
          case 'login':
            if (result.response && result.response.code === 200) {
              setRetryCount(0);
              setRetrying(false);
              setWsHeart(HeartBeatInterval);
              const {imNickName} = info.kfInfo;
              if (!isIMConnect) {//首次链接
                isAutoConnect ? void (0) : machineTransition({type: 'weakText', param: `${imNickName}和您建立了会话`});//(res.data.status_desc || '客服妹子和您建立了会话')
                setIsIMConnect(true);
                setMoreFunList([...moreFunList, ...imMoreFunList]);
              } else { //重连
                //发送队列中发送失败的消息
                // sendAllMsgInQueue();
              }
            }
            break;

          case 'msg':
            messageHandle && messageHandle.current(result);
            break;

          case 'notice':
            hidden ? void (0) : setReadStatus({service_token: Token?.current || info.kfInfo.wsToken || info.kfInfo.tempToken});
            if (result.response && result.response.code === 200) {
              if (result.response.data && (result.response.data.nType === 1002 || result.response.data.nType === 1003 || result.response.data.nType === 1004)) {
                // window.__sid = null;
                setSid('');
                //会话超时。不再重连。
                setNeedRetry(false);
                setRetryCount(0);
                setRetrying(false);
                //刷新wid
                generateWid(true);
                const {imNickName} = info.kfInfo;
                isIMConnectRef.current === true && machineTransition({
                  type: 'weakText',
                  param: `${imNickName}和您会话已断开`
                });//(res.data.status_desc || '客服妹子和您建立了会话')
                closeIMConnect();
              }
              if (result.response.data && result.response.data.nType === 1008) {
                //会话转移提示
                machineTransition({type: 'weakText', param: `正在为您转接客服中...`});
                let msg;
                msg = JSON.parse(result.response.data.nData);
                const cname = msg.to_uname;
                console.log(cname);
                setInfo(prev => {
                  let next = {...prev};
                  next.kfInfo.imNickName = cname;
                  return next;
                });
                machineTransition({type: 'weakText', param: `客服${msg.from_uname}已将会话转移给客服${msg.to_uname}`});
              }

              // if (result.response.data && result.response.data.nType === 1003) {
              //   setNeedRetry(false);
              //   closeIMConnect();
              // }

              if (result.response.data && result.response.data.nType === 2001) {
                //正在输入中
                setInputingFun();
              }
              if (result.response.data && result.response.data.nType === 1006) {
                //满意度邀请
                radarLog({c: 'yq_evaluation'});
                if (!showImFeedback) {
                  setShowImFeedback(true);
                  setFeedbackInitiative(false);
                }
              }
              if (result.response.data && result.response.data.nType === 2003) {
                //快速申请邀请
                collectClues();
              }
              if (result.response.data && result.response.data.nType === 1010) {
                //撤销消息
                machineTransition({type: 'delete', param: result.response.data.nData});
              }

              if (result.response.data && result.response.data.nType === 3001) {
                //自动回复
                machineTransition({
                  type: 'dealResponse', param: {
                    content: result?.response?.data?.message,
                    imInfo: {nickName: info.kfInfo.imNickName},
                    aTarget: true,
                  }
                });
              }

              if (result.response.data && result.response.data.nType === 1014) {
                closeIMConnect();
                Modal.confirm({
                  title: '您已在其他页面发起咨询，当前咨询窗口已失效。',
                  okText: '重新发起咨询',
                  cancelText: '关闭页面',
                  cancelButtonProps: {style: {display: 'none'}},
                  centered: true,
                  onOk: async () => {

                    if (!fullScreen) {
                      window.location.href = `/?rid=${rid}&launch_id=${launch_id}`;
                    } else {
                      window.location.href = `/?rid=${rid}&_tempAuthType=full&_o=${window.location.origin}`;
                    }

                  },
                  onCancel: async () => {
                    const userAgent = navigator.userAgent;
                    if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
                      window.opener = null;
                      window.location.href = "about:blank";
                      window.close();
                    } else {
                      window.opener = null;
                      window.open('', '_self');
                      window.close();
                    }
                  },
                });


              }
            }
            break;
        }

      }
    }

    imgUploadHandler.current = async (files, tempId) => {
      try {
        let res = await uploadFileZK({file: files[0], serviceToken: Token?.current || info.kfInfo.wsToken});
        // console.log(res);

        if (res && res.code === 200 && res.data && res.data.fileUrl) {
          machineTransition({
            type: 'update', id: tempId, callback: (item) => {
              item.imageUrl = res.data.fileUrl;
              item.originFile = {
                ...res.data
              };
              // item.loading = 'error';
              return item
            }
          });
          imSend(JSON.stringify(res.data), tempId, 2, res.data.fileUrl, res?.data?.uniqueName);
          // let imgRes = await sendMsgToZKIM({
          //   serviceToken: info.kfInfo.wsToken,
          //   message: JSON.stringify(res.data),
          //   msgType: 2
          // });
          //
          // if (imgRes && imgRes.code === 200 && imgRes.data && imgRes.data.sendResult) {
          //   machineTransition({
          //     type: 'update', id: tempId, callback: (item) => {
          //       delete item.loading;
          //       item.msgId = res.data.msgId;
          //       return item
          //     }
          //   });
          // } else {
          //   machineTransition({
          //     type: 'update', id: tempId, callback: (item) => {
          //       item.loading = 'error';
          //       return item
          //     }
          //   });
          // }
        } else {
          throw new Error('');
        }
      } catch (e) {
        machineTransition({
          type: 'update', id: tempId, callback: (item) => {
            item.loading = 'error';
            item.canRetry = false;
            return item
          }
        });
        tostTip('上传失败');
      }


    }

    fileUploadHandler.current = async (files, tempId) => {
      try {
        let res = await uploadFileZK({file: files[0], serviceToken: Token?.current || info.kfInfo.wsToken});
        // console.log(res);
        if (res && res.code === 200 && res.data && res.data.fileUrl) {
          machineTransition({
            type: 'update', id: tempId, callback: (item) => {
              item.originFile = {
                ...res.data
              };
              return item
            }
          });
          imSend(JSON.stringify(res.data), tempId, 3, res.data.fileUrl, res?.data?.uniqueName);
          // let imgRes = await sendMsgToZKIM({
          //   serviceToken: info.kfInfo.wsToken,
          //   message: JSON.stringify(res.data),
          //   msgType: 3
          // });

          // if (imgRes && imgRes.code === 200 && imgRes.data && imgRes.data.sendResult) {
          //   machineTransition({
          //     type: 'update', id: tempId, callback: (item) => {
          //       delete item.loading;
          //       item.file = {...item.file, fileUrl: res.data.fileUrl};
          //       item.msgId = res.data.msgId;
          //       return item
          //     }
          //   });
          // } else {
          //   machineTransition({
          //     type: 'update', id: tempId, callback: (item) => {
          //       item.loading = 'error';
          //       return item
          //     }
          //   });
          // }
        } else {
          throw new Error(res?.msg || '');
        }
      } catch (e) {
        machineTransition({
          type: 'update', id: tempId, callback: (item) => {
            item.loading = 'error';
            item.canRetry = false;
            return item
          }
        });
        // tostTip(e?.message || '上传失败');
        message.error(e?.message || '上传失败');
      }
    };


    ws &&
    ws.current &&
    (ws.current.onopen = wsOpenHandle && wsOpenHandle.current);
    ws &&
    ws.current &&
    (ws.current.onclose = wsCloseHandle && wsCloseHandle.current);
    ws &&
    ws.current &&
    (ws.current.onerror = wsErrorHandle && wsErrorHandle.current);
    ws &&
    ws.current &&
    (ws.current.onmessage = wsMessageHandle && wsMessageHandle.current);
  });

  window.__zk_im_init_loading = false;
  const wsInit = async function () {
    if (window.__zk_im_init_loading) {
      return;
    }
    window.__zk_im_init_loading = true;
    // debugger;
    // const wsInit = async function (receptions, type, retry) {
    // console.log(arguments);
    let args;
    if (arguments && arguments.length > 1) {
      args = {
        receptions: arguments && arguments[0],
        type: arguments && arguments[1],
        retry: arguments && arguments[2],
        autoConnect: arguments && arguments[4],
      }
    } else {
      args = (arguments && arguments[0]) || {};
    }
    const {
      receptions, type, retry, fastRetry, autoConnect
    } = args
    // console.log(args);
    // return;
    setShowImChooseBusiness(false);
    let channel_id = info.kfInfo.channel_id;
    // setCancelWaitType(0);
    try {
      let now = new Date().getTime();
      let params = {appId: channel_id, receptions: receptions, type};
      if (imWaiting && imWaiting > 0) {
        params.queue_time = waitingTime
      }

      if (!params.type) {
        params.type = connectIMType;
      }
      // useEffect(()=>{
      //   if(imWaiting){
      //     imWaitingErrorTimeout?.current && clearTimeout(imWaitingErrorTimeout.current);
      //     imWaitingErrorTimeout.current = setTimeout(()=>{
      //       waitingErrorHandle();
      //     },3 * 60/60 * 1000);
      //   }else{
      //     imWaitingErrorTimeout?.current && clearTimeout(imWaitingErrorTimeout.current);
      //   }
      // },[imWaiting]);
      setImWaitingNetWorkError(false);
      if (!imWaitingErrorTimeout?.current) {
        imWaitingErrorTimeout.current = setTimeout(() => {
          waitingErrorHandle();
        }, 2 * 60 * 1000);
      }
      ws?.current?.close(3001);
      ws.current = null;
      let res = await ZKIMinit(params);
      imWaitingErrorTimeout?.current && (clearTimeout(imWaitingErrorTimeout.current), imWaitingErrorTimeout.current = null);
      window.__zk_im_init_loading = false;

      let end = new Date().getTime();
      if (end - now < 500) {
        await pause(end - now);
      }

      if (retry && (!isIMConnectRef.current)) {//重连过程中已断开

        closeIMConnect();
        return window.__zk_im_init_loading = false;
      }

      if (retry) {
        !fastRetry && setIsIMConnect(false);
        ws.current?.close();
      }

      // setWaitQueueInfo(null);
      if (res && res.code === 200 && res.data) {

        if (res.data && res.data.sconfig) {
          try {
            //获取反馈配置信息
            if (!feedbackConfig) {
              let feedbackInfoRes = res.data.sconfig;
              console.log('feedbackInfoRes', feedbackInfoRes);
              setFeedbackConfig(feedbackInfoRes);
              if (feedbackInfoRes && feedbackInfoRes.switch === 1) {
                setImMoreFunList(prev => {
                  return [...prev, {
                    title: t('feedback-star'),
                    icon: 'feedback-star',
                    PCIcon: 'manyidu',
                    callback: () => {
                      setShowMoreFun(false);
                      setFeedbackInitiative(true);
                      setShowImFeedback(true);
                    }
                  }]
                })
                // imFunList.push();
              }
            }
          } catch (e) {

          }
        }

        //触发转人工则刷新wid
        generateWid(true);
        const {cname, uname, token, sid} = res.data;

        if (autoConnect) {
          setReadStatus({service_token: token});
        }
        sid && (setSid(sid));//(window.__sid = sid);

        setInfo(prev => {
          let next = {...prev};
          next.uInfo.imName = uname;
          next.kfInfo.imNickName = cname;
          next.kfInfo.wsToken = token;
          return next;
        });
        Token && (Token.current = token);
        if (res?.msg && res?.msg !== 'Success') {
          tostTip(res.msg);
        }
        setImWaiting(0);
        ZKIMCancelQueue({serviceToken: info.kfInfo.wsToken, queue_time: waitingTime, type: 0});
        try {
          setHttpHeart(HttpHeartInterval);
          httpHeartHandle();
          ws.current = new WebSocket(wsUrl);
          ws.current.onopen = wsOpenHandle && wsOpenHandle.current;
          ws.current.onclose = wsCloseHandle && wsCloseHandle.current;
          ws.current.onerror = wsErrorHandle && wsErrorHandle.current;
          ws.current.onmessage = wsMessageHandle && wsMessageHandle.current;
        } catch (e) {
          closeIMConnect();
          return window.__zk_im_init_loading = false;
        }

      } else {
        console.log('请求init异常', res);
        closeIMConnect();
        if (res && res.code === 2001 && res.data) {
          const {token, queueRank} = res.data;
          setInfo(prev => {
            let next = {...prev};
            next.kfInfo.wsToken = token;
            return next;
          });
          Token && (Token.current = token);
          setWaitQueueInfo(queueRank);
          setImWaiting(IM_WAITING_INTERVAL);
        } else if (res && res.code === 2008) {
          setImWaiting(0);
          setLeaveContent(res.data.content);
          setShowImLeftMessage(true);
          setLeaveMessageToken(res.data.token);
          updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
        } else {
          setImWaiting(0);
          tostTip(res.msg || '网络请求异常');
          updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
          // setIsIMConnect(false);
        }

      }
    } catch (e) {
      if (fastRetry) {
        tostTip('您当前网络异常');
        setImWaiting(0);
        setImWaitingNetWorkError(false);
        imWaitingErrorTimeout?.current && (clearTimeout(imWaitingErrorTimeout.current), imWaitingErrorTimeout.current = null);
        closeIMConnect();
        machineTransition({type: 'weakText', param: `连接异常，请重新连接`});
        return;
      }
      if (retry && (!isIMConnectRef.current)) {//重连过程中已断开
        closeIMConnect();
        return window.__zk_im_init_loading = false;
      }
      window.__zk_im_init_loading = false;
      //失败重连
      // machineTransition({type: 'weakText', param: `retry1`});
      wsRetry();
      if (!waitQueueInfo) {
        waitingErrorHandle()
      }
    } finally {
      window.__zk_im_init_loading = false;
    }

  };

  const wsClose = () => {
    ws && ws.current && ws.current.close && ws.cursubmitBusCluerent.close();
  }


  const wsRetry = () => {
    if (retrying) {
      return;
    }
    setRetrying(true);
    if (retryCount < 5 && isIMConnectRef && isIMConnectRef.current) {
      setRetryCount(retryCount + 1);
    } else if (retryCount >= 5 && isIMConnect) {
      //失败重连
      updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
      machineTransition({type: 'weakText', param: `连接异常，请重新连接`});//(res.data.status_desc || '客服妹子和您建立了会话')
      closeIMConnect();
    } else {
      closeIMConnect();
      const {imNickName} = info.kfInfo;
      // machineTransition({type: 'weakText', param: `连接异常，请重新连接`});//(res.data.status_desc || '客服妹子和您建立了会话')
    }
  };


  // “发送” 的处理函数
  async function originSend(e, id, {isLoginApply, resp, record, fastRetry} = {}) {
    // param = { question: ''} // 请求答案的参数
    delete param.intent_id;
    const _this = this;
    const _args = arguments;
    const stringToSend = xssFilters(e.trim(), xssOptions);

    if (!stringToSend) {
      return
    }


    //记录历史消息
    // let questionMap = {...questionMap};
    let countItem = questionMap[`${stringToSend}`] || {};
    let count = (countItem?.count ? countItem?.count + 1 : 1);
    let continuous = (countItem?.continuous ? countItem?.continuous + 1 : 1);
    questionMap[`${stringToSend}`] = {count: count, continuous: continuous};
    for (let key in questionMap) {
      if (key !== stringToSend) {
        questionMap[key].continuous = 0;
      }
    }


    //取消线索引导语
    clearTimeout(guideTimeoutId);
    updateGuideIndex(null, guideSendModel);

    //如果已连接IM。则走IM的发送
    if (isIMConnect || (isIMConnectRef && isIMConnectRef.current)) {
      if (fastRetry) {
        ws.current && ws.current.close(1000, 'active close');
        ws.current = null;
        await wsInit({
          receptions: receptionMember,
          type: undefined,
          retry: true,
          fastRetry: true,
        });
      }
      imSend(stringToSend);
      return;
    }

    if (current === 'waitGame') {
      tostTip('请先选择游戏')
      // param.game = stringToSend
      // machineTransition({ type: current, param: e })
    } else {
      param.question = stringToSend

      let qus = ''
      if (param.game === '通用' || param.game === '' || param.game === undefined) { // 通用库不需要拼接
        qus = param.question
      } else {
        qus = `${param.question}_${param.game}`
      }

      //如果存在域账号则带入(360+适配)
      if (info && info.uInfo && info.uInfo.account) {
        param.account = info.uInfo.account;
      }


      let method = getAnswer;

      if ((id && record && record.type && record.type === 4)) {
        param.intent_id = id;
      } else if (id) {
        method = getAnswerById;
        param.question_id = id;
        param.tag = info.uInfo.account || randomTag || (Cookies.get('uTag'));
      }

      //是否需要匹配关键字转人工
      if (info.kfInfo && info.kfInfo.customer_mode !== 1 && !isBan && autoIMConfigData?.key_word_model === 1 && autoIMConfigData?.words?.length) {
        const {words} = autoIMConfigData;
        console.log('关键字转人工', words);
        for (let i = 0; i < words.length; i++) {
          const item = words[i];
          if (item.word) {
            switch (item.match_type) {
              case 1:
                if (stringToSend === item.word) {
                  console.log('1完全匹配', item);
                  radarLog({c: 'gjc_transfer'});
                  connectIM(false, '', item?.distribution_model === 2 ? item.receptions : undefined, 2);
                  return;
                }
                break;
              case 2:
                if (stringToSend.indexOf(item.word) >= 0) {
                  console.log('2模糊匹配', item);
                  radarLog({c: 'gjc_transfer'});
                  connectIM(false, '', item?.distribution_model === 2 ? item.receptions : undefined, 2);
                  return;
                }
                break;
            }
          }

        }

      }

      // machineTransition({type: 'delete', param: result.response.data.nData});


      if (method !== getAnswerById) {
        AIControl.apply(this, [{
          e: e,
          question: qus,
          createEventSource,
          eventSourceState,
          eventSourceData,
          info,
          param,
          reducer: machineTransition,
        }]);
        return;

      }

      // 显示输入
      !isLoginApply && machineTransition({type: 'waitQuestion', param: stringToSend})

      let tempMsgId = uuid();
      machineTransition({
        type: 'dealResponse', param: {
          // content: `<div>123</div>`,
          component: LoadingComponent,
          // id: tempMsgId,
          commons: info.kfInfo.guess,
          msgId: tempMsgId,
        }
      });

      try {
        // 获取问题的答案
        method(param).then(async resp => {
          machineTransition({type: 'delete', param: tempMsgId});
          console.log('question map', questionMap, countItem);
          console.log(resp);

          if (parseInt(resp.errno) !== 0) {
            machineTransition({
              type: 'init', param: {
                content: resp.errmsg || t('wrong')
              }
            })
            return
          }


          //为每条消息生成随机id
          const msgId = uuid();
          setCurrentAutoIMItemID(msgId);

          //命中猜你想问
          if (resp.data && resp.data.guess && resp.data.guess.length) {
            machineTransition({
              type: 'guessResponse', param: {
                content: resp.data.answer,//|| info.kfInfo.guide,
                list: [...resp.data.guess.slice(0, GUESS_LIMIT)],
                id: resp.data.id || '',
                msgId: msgId,
              }
            })
            countResponse('GuessResponse');
            countRepeatResponse(countItem);
            return;
          }

          //命中重复知识库
          if (resp.data && resp.data.repeat && resp.data.repeat.length) {
            machineTransition({
              type: 'guessResponse', param: {
                content: resp.data.answer,//|| info.kfInfo.guide,
                list: [...resp.data.repeat.slice(0, GUESS_LIMIT)],
                id: resp.data.id || '',
                msgId: msgId,
              }
            })
            // countResponse('GuessResponse');
            countRepeatResponse(countItem);
            return;
          }

          if (!isLoginApply && resp.data && resp.data.need_login) {
            //如果命中登录节点或大会员体系
            let res = await loginControl.login();
            console.log(_this, _args);
            if (res) {
              send.apply(_this, [_args[0], undefined, {isLoginApply: true, resp}]);
              return;
            } else {
              return;
            }
          }

          if (resp?.data?.dialogue_id) {
            //如果有多轮对话id则保存
            operateMultiId({
              id: resp.data.dialogue_id, expand: {is_big_vip: resp.data?.is_big_vip}
            });
            send.apply(_this, [_args[0], undefined, {resp}]);
            return;
          }

          //多轮对话开始节点立即触发回复节点
          if (!resp?.data?.dialogue_id && resp?.data?.select_list && resp?.data?.select_list?.list?.length && resp?.data?.select_list?.type === 'dialogue_faq') {
            machineTransition({
              type: 'guessResponse', param: {
                content: resp.data.answer,//|| info.kfInfo.guide,
                list: [...resp.data.select_list?.list],
                id: resp.data.id || '',
                msgId: msgId,
              }
            })
            return;
          }

          machineTransition({
            type: 'dealResponse', param: {
              _id: resp.data?._id,
              contentList: resp.data.ext_answer,
              content: resp.data.answer || noAnswer,
              list: resp.data.recommend ? [...resp.data.recommend] : [],
              id: resp.data.id || '',
              commons: info.kfInfo.guess,
              msgId: msgId,
              typedInput: true,
            }
          });

          switch (resp?.data?.answer_type) {
            case 0:
              countResponse('UnknownResponse');
              break;
            case 1:
              countResponse('DirectlyResponse');
              break;
            case 2:
              //寒暄回复打断连续计数
              countResponse('noType');
              break;
            case 3:
              countResponse('GuessResponse');
              break;
          }
          countRepeatResponse(countItem);


        }).catch(error => {
          machineTransition({type: 'delete', param: tempMsgId});
          machineTransition({
            type: 'init', param: {
              content: `网络异常，请稍后再试！`
            }
          })
        })
      } catch (e) {
        machineTransition({type: 'delete', param: tempMsgId});
        machineTransition({
          type: 'init', param: {
            content: `网络异常，请稍后再试！`
          }
        })
      }

    }
  }

  let send = new Proxy(originSend, {
    apply(target, thisArg, argArray) {
      if (eventSourceState === 1) {
        let _str = '请在当前回复结束后再提问';
        return isMobile ? tostTip(_str) : message.warning(_str);
      }

      // if (info?.kfInfo?.robot_ai && !(isIMConnect || (isIMConnectRef && isIMConnectRef.current))) {
      //   return AIControl.apply(thisArg, [{
      //     e: (argArray && argArray[0]),
      //     createEventSource,
      //     eventSourceState,
      //     eventSourceData,
      //     info,
      //     reducer: machineTransition,
      //   }]);
      // }

      // console.log(argArray);
      let multiId = operateMultiId({refresh: true});
      console.log('multiId', multiId);
      if (multiId && multiId.id) {//命中多轮对话拦截发送
        //初次请求已返回答案不再重复提交
        const {resp, msgId} = (argArray && argArray.length >= 3 && argArray[2]) || {};
        let args = [
          {
            e: (argArray && argArray[0]),
            id: (argArray && argArray[1]),
            reducer: machineTransition,
            info,
            t,
            connectIM,
            loginControl,
            resp,
            msgId: msgId,
            expand: multiId.expand
          }
        ]
        processControl.apply(thisArg, args);
      } else {
        target.apply(thisArg, argArray);
      }
    }
  });

  //加载最新留言消息
  async function updateNewMessage() {
    // getHistoryHandle('down');
    // return;
    if (dialogList && dialogList.length) {
      // let lastIndex = dialogList.findLastIndex(item => item._id && item._id !== '' && item.chatType);
      // if (lastIndex === -1) {
      //   return;
      // }
      // let lastItem = dialogList[lastIndex];
      // let msgId = lastItem._id || '';
      // let robotId = lastItem.robotId || '';
      try {
        let res = await getLeaveMessageHistoryList({
          serviceToken: Token?.current || info?.kfInfo?.tempToken,
          appId: info?.kfInfo?.channel_id,
        });
        console.log(res);
        if (res && res.data.list && Array.isArray(res.data.list)) {
          machineTransition({
            type: 'addNewMessage', param: {
              list: res.data.list,//.filter(item => item.chatType === 3)
            }
          })
        }
      } catch (e) {

      }
    }
    // return;
  }

  //加载更多、拉取留言、IM重连过程中补偿聊天记录
  async function getHistoryHandle(direction = 'up') {
    let arr = Array.isArray(dialogList) ? dialogList : [];
    // if (dialogList && dialogList.length) {
    let firstItem = arr[0] || null;
    let firstIndex = arr.findIndex(item => item._id && item._id !== '' && item.chatType);
    let msgId = arr[firstIndex]?._id || '';
    let robotId = firstItem?.robotId || info?.kfInfo?.robot_id || '';
    setAddDialogActive('load-loading');
    try {
      let method = getHistoryList;
      let params = {
        serviceToken: Token?.current || info?.kfInfo?.tempToken,
        appId: info?.kfInfo?.channel_id,
        robot_id: robotId,
        last_msg_id: msgId,
      };
      direction === 'up' ? (params.last_msg_id = msgId) : (params.is_latest = 1);
      let res = await method(params);
      setAddDialogActive('load');
      if (res?.data?.list?.length || res?.data?.length) {
        machineTransition({
          type: 'addHistory',
          param: {
            list: res.data.list || res.data || [],
            init: false,
          },
          robotInfo: info
        })
      }
      return res;
    } catch (e) {
    }
    setAddDialogActive('load');
    return null;
  }

  //加载历史记录
  async function learnMore() {
    if (dialogList && dialogList.length) {
      let firstItem = dialogList[0];
      let cid = firstItem.cid || '';
      setAddDialogActive('load-loading');
      const openId = getWXOpenID();
      let res = await getIMHistoryData({partnerid: openId, cid: cid});
      setAddDialogActive('load');
      if (res && res.errno === 0 && res.data && res.data.length) {
        machineTransition({
          type: 'addRecord', param: {
            list: res.data
          }
        })
      } else {
        setHaveMoreData(false);
      }
      console.log(res);
    }
    return;
  }

  async function zkLearnMore() {
    try {
      let res = await getHistoryHandle('up');
      if (res?.data?.list?.length || res?.data?.length) {
      } else {
        setZkHaveMoreData(false);
      }
    } catch (e) {
    }
  }

  // 游戏列表点击的处理函数
  function gamesend(e) {
    const stringToSend = e.trim()
    if (!stringToSend) {
      return
    }
    // 初始化游戏名
    param.game = stringToSend
    machineTransition({type: 'waitGame', param: stringToSend, isMobile})
  }

  // 咨询其他游戏的处理函数
  function change() {
    //需要重新返回让用户选择游戏列表
    // current = 'init'
    changeCurrent('init');
    // machineTransition({ type: current })
    changeInit(true)
  }

  // 用户线索收集
  async function collectClues() {
    // CluesCollect
    let res = await getRegionsTree();
    // console.log(res);
    if (res && Array.isArray(res.data)) {
      let tempId = new Date().getTime();
      machineTransition({
        type: 'dealResponse',
        id: tempId,
        param: {
          component: CluesCollect,
          componentName: 'CluesCollect',
          options: {
            isIMConnect: isIMConnect,
            token: Token?.current || info.kfInfo.wsToken,
            regData: res.data, finishHandle: () => {
              set__(new Date());
            }
          }
        },
      });
    }


  }

  //IM发送
  async function imSend(str, id, msgType = 1, fileUrl, uniqueName) {
    const openId = getWXOpenID();
    let index = dialogList.length;
    let msgId = id || uuid();
    let sendItem = {type: 'sendMsg', param: str, loading: 'loading', imSend: true, msgId: msgId, fileUrl, msgType};
    if (msgType === 1) {
      machineTransition(sendItem);
    }


    //优先匹配定制化智齿im
    if (info.kfInfo && info.kfInfo.robot_im_sobot && info.kfInfo.robot_im_sobot.status === 1) {
      try {
        let res = await sendMsgToIM({partnerid: openId, content: str, msg_type: 'text'});
        if (res && res.errno === 0 && res.data && res.data.status === '1') {
          machineTransition({
            type: 'update', index, callback: (item) => {
              delete item.loading;
              return item
            }
          });
        } else {
          machineTransition({
            type: 'update', index, callback: (item) => {
              item.loading = 'error';
              return item
            }
          });
        }
      } catch (e) {
        machineTransition({
          type: 'update', index, callback: (item) => {
            item.loading = 'error';
            return item
          }
        });
      }
    } else {
      try {
        let res = await sendMsgToZKIM({
          serviceToken: Token?.current || info.kfInfo.wsToken,
          message: str,
          msgType: msgType
        });
        console.log(res);
        if (res && res.code === 200 && res.data && res.data.sendResult) {
          machineTransition({
            type: 'update', msgId: msgId, callback: (item) => {
              delete item.loading;
              item.msgId = res.data.msgId;
              uniqueName && (item.uniqueName = uniqueName);
              if (msgType === 3 && fileUrl) {
                item.file = {...item.file, fileUrl: fileUrl};
              }
              if (msgType === 2 && fileUrl && fromSDK) {
                item.content = `<div _img_src="${fileUrl}">${item.content}</div>`;
                // consol
              }
              return item
            }
          });
        } else {
          if (res && res.code === 2004) {
            //链接已断开不进行重连
            machineTransition({
              type: 'update', msgId: msgId, callback: (item) => {
                item.loading = 'error';
                return item
              }
            });
            closeIMConnect();
            return;
          }
          throw new Error('');
        }
      } catch (e) {

        console.log('发送失败了,此时链接状态', isIMConnectRef.current)

        // if (!isIMConnectRef.current) {
        machineTransition({
          type: 'update', msgId: msgId, callback: (item) => {
            item.loading = 'error';
            return item
          }
        });
        // return;
        // }

        sendItem.retryCount = (sendItem.retryCount || 0) + 1;
        if (sendMsgQueue && sendMsgQueue.length === 0) {
          // machineTransition({type: 'weakText', param: `retry2`});
          wsRetry();
        }
        setSendMsgQueue(prev => {
          prev = [...prev, sendItem];
          return prev;
        });

      }
    }
    // console.log(res);
  }

  //图片上传功能
  async function imgUpload(files, fromZK) {

    let fileSize = files[0].size;
    if (fileSize > IMAGE_LIMIT && fromZK) {
      return tostTip('上传文件过大，请重新选择');
    }

    // let index = dialogList.length;
    let tempId = new Date().getTime();
    machineTransition({
      type: 'sendImg',
      param: `<img src="${window.URL.createObjectURL(files[0])}" />`,
      loading: 'loading',
      id: tempId,
      msgId: tempId,
      msgType: 2,
    });

    // uploadFileZK
    if (fromZK) {
      imgUploadHandler.current && imgUploadHandler.current(files, tempId);
      // let res = await uploadFileZK({file:files[0],serviceToken: info.kfInfo.wsToken});
    } else {
      let res = await uploadImg(files[0]);
      if (res && res.errno === 0 && res.data && res.data.url_https) {
        const openId = getWXOpenID();
        let imgRes = await sendMsgToIM({partnerid: openId, content: res.data.url_https, msg_type: 'image'});

        if (imgRes && imgRes.errno === 0 && imgRes.data && imgRes.data.status === '1') {

          machineTransition({
            type: 'update', id: tempId, callback: (item) => {
              delete item.loading;
              return item
            }
          });
        } else {
          machineTransition({
            type: 'update', id: tempId, callback: (item) => {
              item.loading = 'error';
              return item
            }
          });
        }

      } else {
        machineTransition({
          type: 'update', id: tempId, callback: (item) => {
            item.loading = 'error';
            return item
          }
        });
      }
    }
  }

  //文件上传功能
  async function fileUpload(files) {

    // ["jpeg", "jpg", "png", "gif", "bmp", "webp"]
    if (/\.(jpg|jpeg|JPEG|png|gif|GIF|JPG|PNG|bmp|BMP|webp|WEBP)$/.test(files[0].name)) {
      return imgUpload(files, true);
    }
    console.log(files);
    let fileSize = files[0].size;
    if (fileSize > FILE_LIMIT) {
      return tostTip('上传文件过大，请重新选择');
    }

    let tempId = new Date().getTime();
    machineTransition({
      type: 'sendFile',
      loading: 'loading',
      id: tempId,
      msgId: tempId,
      msgType: 3,
      component: ChatFileItem,
      file: {name: files[0].name, size: files[0].size},
    });

    fileUploadHandler.current && fileUploadHandler.current(files, tempId);
  }

  //机器人初始化
  async function changeInit(flag) {

    // console.log(moment().format('YYYY-MM-DD HH:mm:ss'));

    // initL
    for (let i = 0; i < FunList.length; i++) {
      let item = FunList[i];
      try {
        await item({rid});
      } catch (e) {
      }
    }


    // await initQUC();
    // setTimeout(async () => {
    // await getUserData();
    // await loginHandle();
    // }, 10000);
    try {
      if (current === 'init') {
        setLeaveMessageHeart(180000);
        // setLeaveMessageHeart(3000);
        if (WXCode && isWeixin() && !getWXOpenID()) {
          // alert(WXCode);
          const res = await getOpenId({wxcode: WXCode, mark: getWXMark(), rid: rid});
          if (!res.errno) {
            // alert(JSON.stringify(res));
            setWXOpenID(res.data.partnerid);
            setHaveMoreData(true);
          }

        }

        // const urlSearch = queryString.parse(location.search);
        // const {timeStamp} = urlSearch;

        let params = {
          rid
        };//转发全部url参数

        // console.log('xx', res);
        let scriptRes;
        try {
          scriptRes = await loadScript(`https://pub-shbt.s3.360.cn/zhike-admin/robot_${rid}_conf.js?_t=${new Date().getTime()}`);
        } catch (e) {

        }
        let infoRes;
        if (!(scriptRes && window?.__ZKSDK_CONFIG)) {
          infoRes = await getInfo(params);
        }
        let Info = {};
        let JsonInfo;
        if (window?.__ZKSDK_CONFIG) {
          JsonInfo = window?.__ZKSDK_CONFIG;
          Object.keys(JsonInfo).forEach(function (key) {
            Info[key] = JSON.parse(JsonInfo[key]);
          })
        } else {
          Info = infoRes?.data;
        }
        ;

        // debugger;
        let robotSettingInfo;
        let robotSettingId = "launch_" + launchId;
        if (Info[robotSettingId]) {
          robotSettingInfo = Info[robotSettingId];
          if (robotSettingInfo.guide_switch && robotSettingInfo.guide_switch === 1) {
            Info.robot_info.guide_switch = 1;
            Info.robot_info.commons = robotSettingInfo.commons;
            Info.robot_info.common_message = robotSettingInfo.common_message;
          }

          if (robotSettingInfo.greet_switch && robotSettingInfo.greet_switch === 1) {
            Info.robot_info.greet_switch = 1;
            Info.robot_info.greet = null;
            Info.robot_info.greets = robotSettingInfo.greets;
          }
        }


        let toHumanConfig;
        if (Info && Info.transfer_to_human) {
          toHumanConfig = Info.transfer_to_human;
        } else {
          const res = await getAutoIMConfig(params);
          res && res.data && (toHumanConfig = res.data);
        }
        toHumanConfig && setAutoIMConfigData(toHumanConfig);
        // debugger;
        //获取微信用户openid (判断条件为有im信息且为微信形式且为微信客户端并未登录)
        if (Info && Info.robot_im_sobot && Info.robot_im_sobot.user_identity === '1' && isWeixin() && !getWXOpenID() && !WXCode) {
          const {appid, mark} = Info.robot_im_sobot;
          setWXMark(mark);
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base`;
          return;
        }


        // 是否开启主动关闭会话
        if (Info && Info.robot_info && Info.robot_info.close_session_switch === 1) {
          setUserCloseSession(true);
        }
        //滚动通知
        if (Info && Info.robot_info && Info.robot_info.notice_switch === 1 && Info.robot_info.notice_content) {
          setNoticeContent(Info.robot_info.notice_content);
        }

        //设置语言
        if (Info && Info.robot_info && Info.robot_info.language) {
          i18n.changeLanguage(Info.robot_info.language);
          document.title = t('title');

          //政企特殊处理。后续title做成可配置项
          rid === '526606b7-c793-4b25-8d3d-87327207e514' ? document.title = '内部反馈' : void (0);

          //去360化特殊处理
          (rid === 'd2bdc571-79be-4c16-8230-fed9ede1bc52' || rid === 'e2f294da-5f86-4488-97ec-ed51c76f3dae') ?
            document.title = '智能客服' : void (0);
        }

        //设置浏览器title
        if (Info && Info.robot_info && Info.robot_info.browser_tab) {
          document.title = Info.robot_info.browser_tab;
        }

        //设置浏览器icon
        if (Info && Info.robot_info && Info.robot_info.browser_icon) {
          // document.title = Info.robot_info.browser_tab;
          setBrowserIcon(Info.robot_info.browser_icon);

        }

        // 暂存无答案回复
        noAnswer = Info && Info.robot_info && Info.robot_info.no_answer || noAnswer


        //线索收集信息
        let formInfo = await getFormInfo(rid);
        if(Array.isArray(formInfo)){
          try {
            formInfo = formInfo.filter(item=>item.switch === 1);
          }catch (e){}
        }
        console.log('formInfo', formInfo);

        // 暂存 客服 和 用户 信息
        let baseInfo = {
          kfInfo: {
            formInfo: formInfo || null,
            robot_ai: Info?.robot_info?.robot_ai || null,
            robot_id: Info?.robot_info?.record_id || '',
            browserIcon: Info && Info.robot_info && Info.robot_info.browser_icon || '',
            browserTab: Info && Info.robot_info && Info.robot_info.browser_tab || '',
            url: Info && Info.robot_info && Info.robot_info.logo || '',
            name: Info && Info.robot_info && Info.robot_info.name || '萌萌',
            color: Info && Info.robot_info && Info.robot_info.color || '',
            btnColor: Info && Info.robot_info && Info.robot_info.button_color || '',
            nickName: Info && Info.robot_info && Info.robot_info?.nickname || Info.robot_info?.nick_name || Info.robot_info?.name || '',
            guide: Info && Info.robot_info && Info.robot_info.guide || '',
            guess: Info?.robot_info?.guess || '',
            group_guide_msg: Info?.robot_info?.group_guide_msg || '',
            group_switch: Info?.robot_info?.group_switch || 2,
            open_status: Info && Info.robot_info && Info.robot_info.open_status,
            customer_mode: Info && Info.robot_info && Info.robot_info.customer_mode,
            channel_id: Info && Info.robot_info && Info.robot_info.channel_id,
            commons: (Info && Info.robot_info && Info.robot_info.common_message) || '',
            greet: Info?.robot_info?.greet || (Array.isArray(Info?.robot_info?.greets) ? Info?.robot_info?.greets[Math.floor(Math.random() * Info?.robot_info?.greets.length)] : '') || '',
          },
          uInfo: {
            url: Info && Info.userimg || '',
            name: Info && Info.username || ''
          }
        };


        //是否开启关联机器人
        if (Info.robot_info && Info.robot_info.relate_robot === 1 && Array.isArray(Info.robot_info.relate_robots)) {
          baseInfo.kfInfo.relateRobots = Info.robot_info.relate_robots;
        }

        //点赞点踩信息
        if (Info.robot_info && Info.robot_info.speech_crafts && Info.robot_info.speech_crafts.length > 0) {
          let upContent;
          let downContent;
          for (let i = 0; i < Info.robot_info.speech_crafts.length; i++) {
            let speechcraft = Info.robot_info.speech_crafts[i];
            if (speechcraft && speechcraft.tricks_type === 1) {
              upContent = speechcraft.content;
            }
            if (speechcraft && speechcraft.tricks_type === 2) {
              downContent = speechcraft.content;
            }
          }

          baseInfo.kfInfo.upContent = upContent;
          baseInfo.kfInfo.downContent = downContent;
        }

        setInfo(baseInfo);

        let funList = [];

        //增加留言入口
        if (Info && Info.robot_msg && Info.robot_msg.status === '1' && Info.robot_msg.link_h5) {
          isMobile ? funList.push({
            title: t('leave-word'),
            icon: 'leave-word',
            jumpUrl: Info.robot_msg.link_h5
          }) : void (0);
        }


        //智齿im配置
        if (Info && Info.robot_im_sobot && Info.robot_im_sobot.status === 1) {
          setInfo(prev => {
            let next = {...prev};
            next.kfInfo.robot_im_sobot = Info.robot_im_sobot;
            return next;
          })

          let imFunList = [];


          imFunList.push({
            title: t('upload-file'),
            icon: 'upload-file',
            isFile: true,
            callback: (files) => {
              imgUpload(files);
            }
          });

          imFunList.push({
            title: t('feedback-star'),
            icon: 'feedback-star',
            callback: () => {
              setShowMoreFun(false);
              setShowFeedback(true);
            }
          });

          setImMoreFunList(imFunList);
        }

        //知客im配置
        if (Info && Info.robot_info && Info.robot_info.customer_mode != 1 && !(Info.robot_im_sobot?.status === 1)) {


          let imFunList = [];

          imFunList.push({
            icon: isMobile ? 'upload-file' : 'picture',
            isFile: true,
            title: t('upload-file'),
            callback: (files) => {
              // console.log(files);
              // imgUpload(files, true);
              fileUpload(files, true);
            }
          });

          //上传文件
          imFunList.push({
            icon: isMobile ? 'file-upload' : 'file',
            isFile: true,
            title: '文件',
            accept: '*',
            callback: (files) => {
              fileUpload(files, true);
            }
          });


          setImMoreFunList(imFunList);
        }

        // 隐藏转人工
        if (Info && Info.robot_info?.hide_human_button === 1) {
          setHideButton(true)
        } else {
          setHideButton(false)
        }
        //获取线索引导语信息
        if (Info && Info.robot_info?.clue_guide_switch === 1 && Info.robot_info?.clue_guide_rule && Info.robot_info?.clue_guide_rule !== 0) {

          const guideRes = await getGuideList();
          if (guideRes.errno === 0 && Array.isArray(guideRes.data)) {
            setGuideSendModel(Info.robot_info.clue_guide_rule);
            setCurrentGuideIndex(getGuideIndex(Info.robot_info.clue_guide_rule));
            setGuideList(guideRes.data);
            // setGuideList([
            //   {
            //     content: "tempor",
            //     delay: 5
            //   },
            //   {
            //     content: "laborum in",
            //     delay: 8
            //   },
            //   {
            //     content: "proident dolore ad irure",
            //     delay: 5
            //   },
            // ]);
          }

        }

        //设置更多功能板块
        setMoreFunList(funList);


        if (!isInit && question) {
          // current = "waitQuestion";
          changeCurrent("waitQuestion");
          send(question);
          setIsInit(true);
          return;
        }

        if(Info.robot_info?.login_info && typeof Info.robot_info?.login_info === 'string'){
          try {
            Info.robot_info.login_info = JSON.parse(Info.robot_info?.login_info);
          }catch (e){
          }
        }
        console.log('判断是否开启登陆', Info.robot_info?.login_info)
        //判断是否开启登陆
        if (Info.robot_info?.login_info && Info.robot_info?.login_info.switch === 1) {
          let loginC = new Login({});
          await loginC.init({
            hideCloseBtn: Info.robot_info?.login_info?.force_login === 1 ? true : false,
          });
          if (Info.robot_info?.login_info?.pop_timing?.open_robot === 1) {
            let res = await loginC.login();
          }
          // let data = await loginC.getUserData();

          // !fromSDK && loginC.login();
          if (Info.robot_info?.login_info?.pop_timing?.dialogue_node === 1) {
            setLoginControl(loginC);
          }

        }

        CenterRef.current && CenterRef.current.scrollToBottom && CenterRef.current.scrollToBottom();

        const judgeSDKInit = () => {
          return new Promise(resolve => {
            if (!fromSDK) {
              resolve();
            } else {
              let r = setTimeout(() => {
                firstShowObserver = undefined;
                resolve();
              }, 2 * 60 * 1000);
              firstShowObserver = () => {
                resolve();
                clearTimeout(r);
              }
            }
          });
        }

        // await judgeSDKInit();

        let userInfoRes = await getUserInfo();
        // console.log(userInfoRes);
        // 内网用户登录
        if (parseInt(userInfoRes.errno, 10) === 10005) {
          window.location = `https://login.ops.qihoo.net:4436/sec/login?ref=${encodeURIComponent(window.location.href)}`
          return
        }
        if (parseInt(userInfoRes.errno, 10) !== 0) {
          machineTransition({
            type: 'init', param: {
              content: userInfoRes.errmsg
            }
          })
          setIsInit(true);

          if (is360Plus) {//360+获取不到用户信息则跳转错误页面
            setInfo(prev => {
              return {...prev, error: userInfoRes.errmsg}
            });
          }
          return
        } else {
          const {uid, user_img, user_type, username, account, played, home_user} = userInfoRes?.data || {};
          window.u_id = uid;

          //初始化用户信息则退出多轮对话
          operateMultiId({
            clear: true
          })

          //360+信息
          if (home_user) {
            if (home_user?.errcode === '0') {
              home_user.name && (baseInfo.uInfo.name = home_user.name);
              home_user.avatarUrl && (baseInfo.uInfo.url = home_user.avatarUrl);
              home_user.account && (baseInfo.uInfo.account = home_user.account);
            }
          } else if (username) {
            baseInfo.uInfo.name = username;
            baseInfo.uInfo.url = user_img;
            baseInfo.uInfo.account = account;
          }

          // //获取历史记录的临时token
          let tokenRes = await getTempToken({appId: Info?.robot_info?.channel_id, rid, uid});
          if (tokenRes?.code === 200 && tokenRes?.data?.token) {
            if (tokenRes?.data?.is_ban === 1) {
              setIsBan(true);
            } else {
              setIsBan(false);
            }
            let tempToken = tokenRes?.data?.token;
            setInfo(prev => {
              let next = {...prev};
              next.kfInfo.tempToken = tempToken;
              return next;
            });
            Token && (Token.current = tempToken);

            //加载本地缓存聊天记录
            if (uid && rid) {
              setAddDialogActive('init-load');
              let records = getChattingRecords(`${uid}-${rid}`);
              console.log('加载本地聊天记录缓存', records);
              if (records && Array.isArray(records)) {
                machineTransition({
                  type: 'overwrite',
                  param: records,
                })
                records.map(async record => {
                  if (record && record.file && (record.uniqueName || record.file?.uniqueName)) {
                    let latestRes = await getLatestFile({
                      serviceToken: Token?.current || tempToken,
                      uniqueName: record.uniqueName || record.file?.uniqueName,
                    })
                    if (latestRes && latestRes.data) {
                      machineTransition({
                        type: 'update', msgId: record.msgId, callback: (item) => {
                          delete item.loading;
                          item.file && (item.file.fileUrl = latestRes.data);
                          return item
                        }
                      });
                    }
                    // debugger;
                  }
                })
              }
              lazyDeleteChattingRecords([`${uid}-${rid}`]);
              // setAddDialogActive('');
            }

            // let recentRes = await getRecentChat({
            //   appId: Info?.robot_info?.channel_id,
            //   serviceToken: tempToken,
            //   robot_id: Info?.robot_info?.record_id
            // });
            //
            // // //历史记录已读
            // // if(tokenRes?.data?.auto_transfer === 1){
            // //   setReadStatus({service_token: tempToken});
            // // }
            //
            // if (recentRes?.code === 200 && recentRes?.data?.length) {
            //   machineTransition({
            //     type: 'addHistory',
            //     param: {
            //       list: recentRes.data || [],
            //       init: true,
            //     },
            //     robot_info: baseInfo
            //   })
            // } else {
            //   setZkHaveMoreData(false);
            // }

            //上报init打点信息
            let trackInfo = {
              uid: u_id,
              channel_id: Info?.robot_info?.channel_id,
              robot_id: rid,
              launch_id: launchId,
            };
            postMessage && postMessage({
              type: 'ROBOT-INIT',
              info: trackInfo,
            });
            // 自动重连
            // 仅人工模式下不自动重连（为防止冲突。机器人初始化结束后会自动重连。）
            if (tokenRes?.data?.auto_transfer === 1 && Info.robot_info?.customer_mode !== 1 && Info.robot_info?.customer_mode !== 2) {
              // console.log('tokenRes', tokenRes,Info);
              setIsAutoConnect(true);
              // connectIM();
              machineTransition({
                type: 'init',
                param: {}
              })
            } else if (Info.robot_info?.customer_mode !== 2) {
              let commons = (Info.robot_info.common_message && Info.robot_info.commons && Info.robot_info.commons.length && !flag) ? {
                content: Info.robot_info.common_message,
                list: Info.robot_info.commons,
              } : null;
              if (Array.isArray(commons?.list)) {
                commons.list = commons.list.filter(item => {
                  if (item && item.start_time && item.start_time > 0 && item.end_time && item.end_time > 0) {
                    let now = new Date().getTime();
                    if (now > item.start_time * 100 && now < item.end_time * 100) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return true;
                })
                commons.list.length > 0 ? void (0) : (commons.list = null);
              }

              let randomGreet = Info?.robot_info?.greet || (Array.isArray(Info?.robot_info?.greets) ? Info?.robot_info?.greets[Math.floor(Math.random() * Info?.robot_info?.greets.length)] : '');
              machineTransition({
                type: 'init',
                param: {
                  content: flag ? '' : randomGreet,
                  list: played && played.length > 0 && played.map(item => item.name) || []
                },
                commons: commons,
              })

              //发送初始化记录
              if (!(flag || sourceOrigin)) {
                getAnswer({
                  account: home_user?.account || account,
                  question: randomGreet,
                  send_message_type: 'welcome'
                });
              }

            }

          }
        }
        setInfo(baseInfo);
        setIsInit(true);


        //是否开启初始化表单（仅人工模式下不开启）
        if (baseInfo?.kfInfo?.formInfo && Info.robot_info?.customer_mode !== 2) {
          let pop = getPop(baseInfo?.kfInfo?.formInfo, '1');
          if (pop) {
            const wid = generateWid();
            if (!hasCollectedClues(`${window?.u_id}-${pop.record_id}`)) {
              setFormVisible(true);
              setFormInfo(pop);
            }
          }
        }

        if (!fromSDK) {
          try {
            robotTell({rid});
          } catch (e) {
          }
        }

        //360+信息
        // if (Info.home_user && Info.home_user.errcode === '0') {
        //   baseInfo.uInfo.name = Info.home_user.name || baseInfo.uInfo.name;
        //   baseInfo.uInfo.url = Info.home_user.avatarUrl || '';
        //   baseInfo.uInfo.account = Info.home_user.account;
        // }

        // //获取历史记录的临时token
        // let tokenRes = await getTempToken({appId: Info?.robotinfo?.channel_id, rid});
        // if (tokenRes?.code === 200 && tokenRes?.data?.token) {
        //   if (tokenRes?.data?.is_ban === 1) {
        //     setIsBan(true);
        //   } else {
        //     setIsBan(false);
        //   }
        //   let tempToken = tokenRes?.data?.token;
        //   setInfo(prev => {
        //     let next = {...prev};
        //     next.kfInfo.tempToken = tempToken;
        //     return next;
        //   });
        //
        //   let recentRes = await getRecentChat({
        //     appId: Info?.robotinfo?.channel_id,
        //     serviceToken: tempToken,
        //     robot_id: Info?.robotinfo?.record_id
        //   });
        //
        //   //历史记录已读
        //   setReadStatus({service_token: tempToken});
        //
        //   if (recentRes?.code === 200 && recentRes?.data?.length) {
        //     machineTransition({
        //       type: 'addHistory',
        //       param: {
        //         list: recentRes.data || [],
        //         init: true,
        //       },
        //       robotInfo: baseInfo
        //     })
        //   } else {
        //     setZkHaveMoreData(false);
        //   }
        //   // console.log('tokenRes', recentRes);
        //
        // }
        // if (tokenRes?.data?.uid) {
        //   window.u_id = tokenRes?.data?.uid;
        // }


        // console.log(Info);
        // getInfo(params).then(async resp => {
        //   console.log(resp);
        //
        //   // postMessage(resp);
        //
        //   const Info = resp.data;
        //
        //   // let res = await loginC.login();
        //   // console.log('login res', res);
        //
        //   //常见问题引导
        //   // if (Info.robotinfo && ) {
        //   // console.log('info',Info);
        //   // machineTransition({
        //   // })
        //   // }
        //
        //
        // })
      }
    } catch (e) {
      setIsInit(true);
    }

  }

  //断开连接
  function closeIMConnect() {
    // //刷新wid
    // generateWid(true);
    //断开链接不再刷新wid，仅在notice通知时刷新。
    setShowImFeedback(false);
    setIsIMConnect(false);
    setNeedRetry(false);
    setWsHeart(-1);
    // setHttpHeart(-1);
    setRetryCount(0);
    // setImWaitingNetWorkError(false);
    setShowMoreFun(false);
    setIsAutoConnect(false);
    ws.current && ws.current.close(1000, 'active close');
    ws.current = null;
    closeAllMsgInQueue();
    clearHeartQueue();
    updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
  }

  //im轮询接收消息
  function IMPolling() {
    const openId = getWXOpenID();
    setInterval(() => {

      getMsgFromIM({partnerid: openId}).then((res) => {
        // console.log(res);
        if (res && res.errno === 0 && res.data) {
          const {msg_type, msg} = res.data;
          if (msg_type === 'text') {
            machineTransition({
              type: 'dealResponse', param: {
                content: msg
              }
            });
          } else if (msg_type === 'image') {
            machineTransition({type: 'sendImg', who: 'left', param: `<img  src="${msg}" />`});
          }
        }
      })
    }, 1000);
  }

  let longPollingLastTime = 0;

  //im长轮询接收消息
  async function IMLongPolling(cid) {

    let nowTime = new Date().getTime();

    if (nowTime - longPollingLastTime > 1000) {//设置节流
      const openId = getWXOpenID();
      try {
        let res = await getMsgFromIMLong({partnerid: openId, cid});
        if (res && res.errno === 0 && res.data) {
          console.log(res);
          const {msg_type, msg, msg_code, agent_nick} = res.data;

          if (msg_code === '204') {
            setIsIMConnect(false);
            machineTransition({type: 'weakText', param: (t('long-time'))});
            return;
          }
          if (msg_type === 'text') {
            machineTransition({
              type: 'dealResponse', param: {
                content: msg,
                imInfo: {nickName: agent_nick},
              }
            });
          } else if (msg_type === 'image') {
            machineTransition({
              type: 'dealResponse', param: {
                content: `<img src=${msg} />`,
                imInfo: {nickName: agent_nick},
              }
            });
            // machineTransition({type: 'sendImg', who: 'left', param: `<img  src="${msg}" />`});
          }
        }
        longPollingLastTime = nowTime;
      } catch (e) {
        longPollingLastTime = nowTime;
        return IMLongPolling(cid);
      }


    } else {
      await setTimeout(() => {
      }, 500);
    }

    if (longPollingState) {
      return IMLongPolling(cid);
    }

  }

  const pause = (time) => {
    return new Promise((resolve, reject) => {
      if (time > 0) {
        setTimeout(() => {
          resolve();
        }, time);
      } else {
        resolve();
      }

    });
  }

  //连接IM
  async function connectIM(autoConnect, prompt, receptions, type) {

    debugger;
    //转人工前判断是否需要线索收集
    if (info?.kfInfo?.formInfo) {
      let pop = getPop(info?.kfInfo?.formInfo, '2');
      if (pop) {
        const wid = generateWid();
        if (!hasCollectedClues(`${window?.u_id}-${pop.record_id}`)) {
          setFormVisible(true);
          setFormInfo({...pop,fromIM: true, connectIMArgsSnapshot: [...arguments]});
          return;
        }
      }
    }

    if (eventSourceState === 1) {
      let _str = '请在当前回复结束后再提问';
      return isMobile ? tostTip(_str) : message.warning(_str);
    }

    setConnectIMType(type);
    //获取反馈配置信息
    try {
      if (false && !feedbackConfig) {
        let feedbackInfoRes = await ZKIMFeedbackInfo({channel_id: (info && info.kfInfo?.channel_id)});
        console.log('feedbackInfoRes', feedbackInfoRes);
        if (feedbackInfoRes && feedbackInfoRes.code === 200 && feedbackInfoRes.data) {
          setFeedbackConfig(feedbackInfoRes.data);

          if (feedbackInfoRes.data && feedbackInfoRes.data.switch === 1) {
            setImMoreFunList(prev => {
              return [...prev, {
                title: t('feedback-star'),
                icon: 'feedback-star',
                PCIcon: 'manyidu',
                callback: () => {
                  setShowMoreFun(false);
                  setFeedbackInitiative(true);
                  setShowImFeedback(true);
                }
              }]
            })
            // imFunList.push();
          }
        }
      }
      // setFeedbackConfig
    } catch (e) {

    }

    isMobile && setImMoreFunList(prev => {
      if (prev.find(item => item?.name === 'close-im-btn')) {
        return prev;
      }
      return [...prev, {
        icon: 'close-fill',
        title: '结束人工',
        name: 'close-im-btn',
        callback: () => {
          headerCloseHandle(true);
        }
      }]
    });


    //触发转人工则退出多轮对话
    operateMultiId({
      clear: true
    })

    if (isIMConnecting?.current != -1) {
      return;
    }

    countResponse('', true);
    machineTransition({
      type: 'showConnectIM',
    });

    //取消线索引导语
    //如果为仅机器人模式则继续发送引导语
    info.kfInfo.customer_mode !== 2 && clearTimeout(guideTimeoutId);


    //优先匹配定制化智齿im
    if (info.kfInfo && info.kfInfo.robot_im_sobot && info.kfInfo.robot_im_sobot.status === 1) {
      //智齿im
      const openId = getWXOpenID();
      let res = await getImChatConnect({partnerid: openId});
      if (res && res.errno === 0 && res.data && res.data.status === '1') {
        radarLog({c: 'wpd_conversation'});
        setIsIMConnect(true);
        setMoreFunList([...moreFunList, ...imMoreFunList]);
        machineTransition({type: 'weakText', param: t('connect-success')});//(res.data.status_desc || '客服妹子和您建立了会话')
        // IMPolling();
        return IMLongPolling();
      } else {
        let str = t('try-again');
        if (res && res.data && res.data.status) {
          switch (res.data.status) {
            case '0':
              str = t('line-up');
              break;
            case '2':
              str = t('busy-try-again');
              break;
            case '3':
              str = t('no-seats');
              break;
          }
        }
        tostTip(str);
      }
    } else {//知客im
      let msgId = uuid();
      console.log(prompt);
      autoConnect ? void (0) : machineTransition({
        type: 'weakText',
        param: prompt || '人工客服转接中，请稍后...',
        msgId: msgId
      });
      autoConnect ? void (0) : isIMConnecting.current = (msgId);
      // alert('智齿连接');
      // let channel_id = info.kfInfo.channel_id;
      // let res = await ZKIMinit({appId: channel_id});

      try {
        if (receptions?.length > 0) {
          setReceptionGroup([]);
          setShowImChooseBusiness(false);
          setReceptionMember(receptions || []);
          wsInit((receptions || []), type, false, false, autoConnect);
        } else {
          if (info.kfInfo.group_switch === 1 && !isAutoConnect) {

            const params = {};
            params.rid = rid;
            let res = await getRobotGroup(params);
            if (res?.data?.length > 0) {
              setReceptionMember(null);
              setReceptionGroup(res.data);
              setShowImChooseBusiness(true);
              setShowBusinessType(type);
            } else {
              setReceptionGroup([]);
              setShowImChooseBusiness(false);
              setShowBusinessType(null);
              setReceptionMember(receptions || []);
              wsInit((receptions || []), type);
            }
          } else {
            setReceptionGroup([]);
            setShowImChooseBusiness(false);
            setReceptionMember(receptions || []);
            wsInit((receptions || []), type, false, false, autoConnect);

          }

        }
      } catch (e) {
        tostTip('您当前网络异常')
        console.log('获取接待组报错')
        isIMConnectRef.current === true && machineTransition({
          type: 'weakText',
          param: `连接异常，请重新连接`
        });
        closeIMConnect();
      }

    }


  }

  //提交反馈
  async function submitFeedback(params) {
    setShowFeedback(false);
    const openId = getWXOpenID();
    params.partnerid = openId;
    let res = await postComment(params);
    if (res && res.errno === 0) {
      setCanFeedback(false);
    }
  }

  //提交留言
  async function submitIMLeaveMessage(values) {
    const params = {};
    params.rid = rid;
    params.content = values;
    params.service_token = leaveMessageToken;
    if (params.content.trim() === "" || params.content === "") {
      tostTip("请输入留言内容!");
    } else {
      setShowImLeftMessage(false);
      let res = await ZKIMLeaveMessage(params) || {};
      if (res && res.code && res.code === 200) {
        tostTip("留言成功!");
        machineTransition({
          type: 'sendMsg',
          param: values,
          imSend: true,
          msgId: res?.data?.msgId,
          _id: res?.data?._id,
          chatType: 3,
        });
      } else {
        tostTip(res.msg || "留言失败，请重新留言");
      }
    }
  }

  async function submitIMFeedback(params, type) {
    const {wsToken} = info.kfInfo;
    if (!wsToken) {
      return;
    }
    params.service_token = Token?.current || wsToken;
    setShowImFeedback(false);
    let res = await ZKIMSubmitFeedback(params);
    if (res && res.code === 200) {
      if (type && type === 'close') {
        return;
      }
      if (feedbackConfig && feedbackConfig.thank_text) {
        machineTransition({type: 'weakText', param: `${feedbackConfig.thank_text}`});
        // tostTip(feedbackConfig.thank_text);
      }
    }
  }

  async function msgRetryHandle(msgItem) {

    // await send
    console.log('retry msg', msgItem);
    if ((isIMConnectRef && isIMConnectRef.current)) {
      machineTransition({type: 'delete', param: msgItem});
      ws.current && ws.current.close(3001, 'active close');
      ws.current = null;
      await wsInit({
        receptions: receptionMember,
        type: undefined,
        retry: true,
        fastRetry: true,
      });
      let tempId = new Date().getTime();
      switch (msgItem?.msgType) {
        case 2:
          // imageUrl
          machineTransition({
            type: 'sendImg',
            param: `<img src="${msgItem.imageUrl}" />`,
            id: tempId,
            msgId: tempId,
            msgType: 2,
            imageUrl: msgItem.imageUrl,
            originFile: msgItem?.originFile
          });
          imSend(JSON.stringify(msgItem?.originFile), tempId, 2, msgItem.imageUrl);
          break;
        case 3:
          machineTransition({
            type: 'sendFile',
            loading: 'loading',
            id: tempId,
            msgId: tempId,
            msgType: 3,
            component: ChatFileItem,
            file: msgItem?.file,
            originFile: msgItem?.originFile
          });
          imSend(JSON.stringify(msgItem?.originFile), tempId, 3,);
          break;
        default:
          await originSend(msgItem.content, null,);
          break;
      }
    }
  }


  //提交输入
  async function submitInput(params) {
    try {
      const {wsToken} = info.kfInfo;
      params.service_token = Token?.current || wsToken;
      params.action = 1;
      params.to_user_type = 1;
      // uploadInput(params);

      // console.log(params);
      let message = {seq: WSSendId(), cmd: 'input', data: params};
      message = JSON.stringify(message);
      ws.current && ws.current.send(message);
    } catch (e) {

    }
  }

  const headerMiniHandle = () => {
    postMessage && postMessage({
      type: 'MINI-HANDLE',
      wid: generateWid(),
      rid: rid,
      uid: window.u_id
    });
  }


  // const headerCloseOkHandle = debounce(async () => {
  //
  // }, 100);
  const headerCloseHandle = (connected) => {
    // debugger;
    if (isIMConnect || connected === true) {
      Modal.confirm({
        title: '您是否要结束会话？',
        okText: '立即结束',
        cancelText: '取消',
        centered: true,
        style: {maxWidth: '80%'},
        onOk: async () => {
          let res = await closeSession({
            serviceToken: Token?.current || info?.kfInfo?.wsToken || info?.kfInfo?.tempToken,
          });
          // console.log(res);
          closeIMConnect();
          machineTransition({type: 'weakText', param: `用户结束了与客服的会话，感谢您的支持`});
          postMessage && postMessage({
            type: 'CLOSE-HANDLE',
            wid: generateWid(),
            rid: rid,
            uid: window.u_id
          });
        },
        onCancel: async () => {

        },
      })
    } else {
      postMessage && postMessage({
        type: 'CLOSE-HANDLE',
        wid: generateWid(),
        rid: rid,
        uid: window.u_id
      });
    }

    // if(fromSDK){
    //
    // }else{
    //   if(isIMConnect){
    //     closeIMConnect();
    //   }else{
    //     // window.close();
    //   }
    // }
  }

  const headerMouseHandle = (e) => {
    // e && e.preventDefault();
    postMessage && postMessage({
      type: 'HEADER-MOUSE-EVENT',
      wid: generateWid(),
      rid: rid,
      uid: window.u_id,
      eventType: e?.type,
      clientX: e?.clientX,
      clientY: e?.clientY,
    });
  }

  const closeTyped = (msgId) => {
    machineTransition({
      type: 'update', msgId: msgId, callback: (item) => {
        item.typedInput = false;
        return item
      }
    });
  }


  //线索收集表单提交
  const submitForm = async (formData, formInfo) => {
    let params = {
      rid,
      collector_id: formInfo.record_id,
      content: JSON.stringify(formData),
    }
    console.log('线索收集表单提交', params);
    setFormVisible(false);
    try {
      let res = await submitMarketing(params);
      console.log(res);
      if (res && res.errno === 0) {
        const wid = generateWid();
        hasCollectedClues(`${window?.u_id}-${formInfo.record_id}`, true);
        tostTip('表单提交成功');
        if (formInfo && formInfo.pop.indexOf('2') >= 0 && formInfo.fromIM === true && formInfo.fromEntrance !== true) {
          // alert(1);
          // formInfo.connectIMArgsSnapshot
          connectIM.apply(null, formInfo.connectIMArgsSnapshot);
        }
        set__(new Date());
        console.log('表单提交成功', formInfo);
        return;
      }
    } catch (e) {
    }
    tostTip('表单提交失败');

    // console.log(res);
  }
  const closeForm = async () => {
    setFormVisible(false);
  }

  // return <FormRender />
  return (
    <>
      <FormModal isSDK={isSDK} isMobile={isMobile} rid={rid} visible={formVisible} formInfo={formInfo}
                 onFinishHandle={submitForm} onCloseHandle={closeForm}/>
      {
        (is360Plus ? <The360Plus info={info} isMobile={isMobile} dialogList={dialogList} gamesend={gamesend} send={send}
                                 isGameRobot={isGameRobot} change={change}/> :
          (isInit ? (<div
            onMouseMove={headerMouseHandle}
            className={`${styles['root']} ${showSidebar ? styles['-with-side-bar'] : ''} ${fullScreen ? styles['-full-screen'] : ''}`}>
            <div
              className={`${isMobile ? styles.mkf_box : styles.kf_box} ${showSidebar ? styles['-with-side-bar'] : ''}`}>
              <Header closeHandle={headerCloseHandle}
                      miniHandle={headerMiniHandle}
                      mouseHandle={headerMouseHandle}
                      isIMConnect={isIMConnect}
                      noticeContent={noticeContent}
                      userCloseSession={userCloseSession}
                      hideHeader={isHideHeaderSpecial} info={info.kfInfo} isMobile={isMobile} fromSDK={isSDK}/>
              <Center haveMore={haveMoreData}
                      noticeContent={noticeContent}
                      msgRetry={msgRetryHandle}
                      ref={CenterRef}
                      isIMConnect={isIMConnect}
                      connectIM={connectIM}
                      countResponse={countDissResponse}
                      setCurrentAutoIMItemID={setCurrentAutoIMItemID}
                      postMessage={postMessage}
                      canCollect={canCollect}
                      showNewInformationTips={showNewInformationTips}
                      setShowNewInformationTips={setShowNewInformationTips}
                      newInformationNumber={newInformationNumber}
                      setNewInformationNumber={setNewInformationNumber}
                      zkHaveMore={zkHaveMoreData}
                      zkLearnMore={zkLearnMore}
                      closeTyped={closeTyped}
                      learnMore={learnMore} active={addDialogActive} clearActive={() => {
                setAddDialogActive('')
              }} hideHeader={isHideHeaderSpecial} isGameRobot={isGameRobot} info={info} list={dialogList}
                      isMobile={isMobile} triggerSend={send}
                      showMoreFun={showMoreFun}
                      triggerGameSend={gamesend}/>
              <Footer info={info.kfInfo} triggerSend={send} triggerChange={change} isMobile={isMobile}
                      entranceFormInfo={getEntrance(info?.kfInfo?.formInfo)}
                      showEntranceForm={showEntranceForm}
                      openForm={(formInfo) => {
                        setFormVisible(true);
                        let info = {...formInfo, fromEntrance: true};
                        setFormInfo(info);
                        set__(new Date());
                      }}
                      stopQuestion={stopQuestion}
                      eventSourceState={eventSourceState}
                      isInputing={isInputing}
                      imWaitingNetWorkError={imWaitingNetWorkError}
                      waitQueueInfo={waitQueueInfo}
                      collectClues={collectClues}
                      canCollect={canCollect}
                      closeHandle={headerCloseHandle}
                      isIMConnect={isIMConnect}
                      isBan={isBan}
                      isSDK={isSDK}
                      isHide={hideButton || isHideRobot}
                      connectIM={connectIM}
                      submitInput={submitInput}
                      querySuggest={isGameRobot ? void 0 : querySuggest}
                      imWaiting={imWaiting}
                      showNewInformationTips={showNewInformationTips}
                      setShowNewInformationTips={setShowNewInformationTips}
                      newInformationNumber={newInformationNumber}
                      setNewInformationNumber={setNewInformationNumber}
                      fileUpload={fileUpload}
                      uploadImg={uploadImg}
                      showMoreFun={showMoreFun}
                      setShowMoreFun={setShowMoreFun}
                      moreFunList={moreFunList}
                      closeImWaiting={() => {
                        setImWaitingNetWorkError(false);
                        setWaitingTime(0);
                        ZKIMCancelQueue({serviceToken: info.kfInfo.wsToken, queue_time: waitingTime, type: 1});
                        // setCancelWaitType(1);
                        setImWaiting(0);
                        updateIMConnectingWeakText.current && updateIMConnectingWeakText.current();
                      }}
                      showChange={isGameRobot}/>
              {canFeedback ?
                <Feedback isMobile={isMobile}
                          submitFeedback={submitFeedback}
                          showFeedback={showFeedback}
                          setShowFeedback={setShowFeedback}/> : null}
            </div>

            <LeaveMessage info={info.kfInfo}
                          isMobile={isMobile}
                          showImLeftMessage={showImLeftMessage}
                          submitIMLeaveMessage={submitIMLeaveMessage}
                          leaveContent={leaveContent}
                          setShowImLeftMessage={setShowImLeftMessage}/>

            {showImChooseBusiness ?
              <ChooseBusiness info={info.kfInfo}
                              isMobile={isMobile}
                              showImChooseBusiness={showImChooseBusiness}
                              closeReceptionModal={closeReceptionModal}
                              showBusinessType={showBusinessType}
                              receptionGroup={receptionGroup}
                              wsInit={wsInit}
                              setReception={setReceptionMember}/> : null}

            <IMFeedback config={feedbackConfig}
                        isMobile={isMobile}
                        submitFeedback={submitIMFeedback}
                        showFeedback={showImFeedback}
                        setShowFeedback={setShowImFeedback}
                        feedbackInitiative={feedbackInitiative}/>
            {showSidebar && !isMobile ? <SideBar/> : null}
          </div>) : <div className="load-container">
            <div className="boxLoading"></div>
          </div>))
      }


    </>
  )
}