import request from '../static/util/request';
import {uploadRequestZK} from '../static/util/request';
import {stringify} from 'qs';

import {getEncryptInfo} from '../static/util/utils';
import {getSid} from '../static/util/store';

const queryString = require('query-string');
const searchParams = queryString.parse(location.search);
const {rid = '', code = '', appid = ''} = searchParams;
const {launch_id = ''} = searchParams;


import Cookies from 'js-cookie';

let imServiceDomain = 'https://im.zhike.360.cn';
switch (process.env.NODE_ENV) {
  case "development":
    imServiceDomain = 'https://qaim.zhike.360.cn';
    // imServiceDomain = 'http://10.16.49.85:16666';
    // imServiceDomain = 'http://10.221.196.234:16666';
    break;
  case "none":
    imServiceDomain = 'https://qaim.zhike.360.cn';
    break;
  default:
    imServiceDomain = 'https://im.zhike.360.cn';
}

export async function robotTell(params) {
  params = {...params};
  return request(`v1/robotTell?${stringify(params)}`);
}

export async function getInfo(params) {
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`v1/robotInfo`, {
    method: 'POST',
    body: params,
  });
}

export async function getUserInfo(params) {
  const info = getEncryptInfo();
  params = {...params, rid, ...info};
  return request(`v1/userInfo?${stringify(params)}`);
}

//获取自动转人工信息
export async function getAutoIMConfig(params) {
  params = {...params, rid};
  return request(`v1/toHumanConf?${stringify(params)}`);
}

export async function getAnswerById(params) {
  params = {...params, rid, launch_id, code, appid};
  return request(`v1/searchQus?${stringify(params)}`);
}

export async function getRegionsTree(params) {
  params = {...params, rid};
  return request(`v1/region_tree?${stringify(params)}`);
}

export async function submitClue(params) {
  params = {...params, rid};
  return request(`v1/business_clue?${stringify(params)}`);
}

export async function submitBusClue(params) {
  // return request(`${imServiceDomain}/api/v1`, {
  //   body: params,
  //   method: 'POST',
  //   withCredentials: true,
  // });
  return request(`${imServiceDomain}/api/v1/user/businessSpread?${stringify(params)}`);
}


// 获取wx-openID
export async function getOpenId(params) {
  return request(`v1/chat/getpartnerid`, {
    method: 'POST',
    body: params,
  });
}

export async function getImChatConnect(params) {
  params = {...params, rid};
  return request(`v1/chat/connect`, {
    method: 'POST',
    body: params,
  });
}


export async function sendMsgToIM(params) {
  params = {...params, rid};
  return request(`v1/chat/send`, {
    method: 'POST',
    body: params,
  });
}

export async function sendMsgToZKIM(params) {
  params = {...params, rid};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/sendMessage?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function uploadFileZK(params) {
  params = {...params, rid};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return uploadRequestZK(`${imServiceDomain}/api/v1/file/imUpload?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}


export async function uploadImg(file) {
  return request(`v1/chat/imageupload`, {
    method: 'POST',
    file: file,
  });
}

export async function getMsgFromIM(params) {
  params = {...params, rid};
  return request(`v1/chat/obtain`, {
    method: 'POST',
    body: params,
  });
}


export async function postComment(params) {
  params = {...params, rid};
  return request(`v1/chat/comment`, {
    method: 'POST',
    body: params,
  });
}


export async function getMsgFromIMLong(params) {
  params = {...params, rid};
  return request(`v1/chat/obtainlong`, {
    method: 'POST',
    body: params,
  });
}

export async function getIMHistoryData(params) {
  params = {...params, rid};
  return request(`v1/chat/historyrecords`, {
    method: 'POST',
    body: params,
  });
}


export async function ZKIMinit(params) {
  const wid = Cookies.get('wid') || '';
  // const sid = window.__sid;
  const sid = getSid();
  params = {...searchParams, ...params, rid, wid, launch_id, sid};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/init.client?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}


//type:1主动取消，缺省为被动取消
export async function ZKIMCancelQueue(params) {
  params = {...params, rid, robot_id: rid};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/queue/cancelQueue?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function ZKIMLogout(params) {
  params = {...params, rid};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/customer/userLogOut?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}


export async function ZKIMFeedbackInfo(params) {
  params = {...params};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/satisfaction/config?${stringify(params)}`);
}

export async function ZKIMLeaveMessage(params) {
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/leaveMessage?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function ZKIMSubmitFeedback(params) {
  params = {...params};
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/satisfaction?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function querySuggest(word) {
  let params = {query: word, robot_id: rid};
  return request(`v1/ai/associationInput`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function setReadStatus(params) {
  params.robot_id = rid;
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/SettingReadStatus?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function getRobotGroup(params) {
  params.rid = rid;
  return request(`v1/robotGroup?${stringify(params)}`);
}

export async function getTempToken(params) {
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/init.clientHistory?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function getRecentChat(params) {
  params.source = 1;
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/chat/getRecentChat?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function getHistoryList(params) {
  params.source = 1;
  params.robot_id = rid;
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/chat/getChatsByUid?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}

export async function getLeaveMessageHistoryList(params) {
  params.rid = rid;
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/getLeaveMessageByUid?${stringify(params)}`, {
    body: params,
    method: 'GET',
    withCredentials: true,
  });
}

export async function uploadInput(params) {
  params.action = 1;
  params.to_user_type = 1;
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/notice/inputText?${stringify(params)}`, {
    body: params,
    method: 'POST',
    withCredentials: true,
  });
}


export async function verifyOrderId(params) {
  return request(`/v1/task/checkReply`, {
    method: 'POST',
    body: params,
  });
}


export async function verifyOrderRelyEMail(params) {
  return request(`/v1/task/addReply`, {
    method: 'POST',
    body: params,
  });
}


export async function getGuideList(params = {}) {
  params.rid = rid;
  return request(`/v1/robotClue?${stringify(params)}`);
}

export async function closeSession(params) {
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/user/closeSession?${stringify(params)}`, {
    method: 'POST',
    body: params,
  });
}

export async function getSessionMsgId(params){
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/chat/getSessionMsgId?${stringify(params)}`);
}

export async function getChatsByMsgId(params){
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`${imServiceDomain}/api/v1/chat/getChatsByMsgId?${stringify(params)}`);
}

export async function getLatestFile(params){
  const info = getEncryptInfo();
  params = {...params, ...info};
  // imFile
  return request(`${imServiceDomain}/api/v1/file/imFile?${stringify(params)}`, {
    method: 'POST',
    body: params,
  });
}


export async function stopAIQuestion(params){
  // imFile
  return request(`/v1/stopAIQus`, {
    method: 'POST',
    body: params,
  });
}



export async function getMarketingCollector(params) {
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`/v1/marketingCollector?${stringify(params)}`);
}

export async function submitMarketing(params){
  const info = getEncryptInfo();
  params = {...params, ...info};
  return request(`/v1/marketingLead?${stringify(params)}`, {
    method: 'POST',
    body: params,
  });
}