// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h5-form-render-container--2VnwN.adm-form {\n  --border-top: none;\n  --border-bottom: none;\n  --border-inner: 0.005rem solid var(--18, rgba(26, 27, 29, 0.08));\n  --adm-color-text-secondary: #1A1B1D;\n  --font-size: 0.16rem;\n  --gap: 0.08rem;\n}\n.h5-form-render-container--2VnwN.adm-form .adm-selector .adm-space.adm-space {\n  --gap: 0.08rem;\n}\n.h5-form-render-container--2VnwN .adm-input-element {\n  --color: #1A1B1D;\n  font-family: \"PingFang SC\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}\n", ""]);
// Exports
exports.locals = {
	"h5-form-render-container": "h5-form-render-container--2VnwN"
};
module.exports = exports;
