import React, {useContext, useRef} from 'react';
import H5FormRender from "../FormRender/H5FormRender";
import {Popup,} from 'antd-mobile';
import styles from './index.less';

const H5Modal = (props) => {
  const {name,description,formData,onFinishHandle=()=>{},onCloseHandle=()=>{}} = props;
  const formRef = useRef(null);
  return <Popup
    visible={true}
    showCloseButton
    onClose={onCloseHandle}
    bodyStyle={{
      borderTopLeftRadius: '.16rem',
      borderTopRightRadius: '.16rem',
      minHeight: '40vh',
      maxHeight: '75vh',
      display:'flex',
      flexDirection: 'column',
    }}
  >
    <div className={styles['H5-header-container']}>
      <div className={styles['title']}>{name}</div>
      <div className={styles['desc']} title={description}>{description}</div>
    </div>
    <H5FormRender onFinishHandle={onFinishHandle} ref={formRef} formData={formData} />
    <div className={styles['H5-footer-container']}>
      <div className={styles['btn']} onClick={()=>{ formRef?.current?.submit()}}>
        提交
      </div>
    </div>
  </Popup>
}

export default H5Modal;